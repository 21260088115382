import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./ProductPage.module.css";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Form from "react-bootstrap/Form";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { InputAdornment } from "@mui/material";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import CheckBox from "./CheckBox";

interface ImageGrid {
  truck: any;
  imageCount: number;
  setImageViewer: (value: boolean) => void;
}

const PhotosParent: FunctionComponent<ImageGrid> = ({
  truck,
  imageCount,
  setImageViewer,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const thumbnailsRef = useRef<{
    show: () => void;
    hide: () => void;
    visible: boolean;
  } | null>(null);
  const [index, setIndex] = useState<number>(-1);

  useEffect(() => {
    console.log("photos parent vvv");
    console.log(truck);
  }, [truck]);
  return (
    <div className={styles.photosParent}>
      <Lightbox
        close={() => setIndex(-1)}
        plugins={[Thumbnails]}
        thumbnails={{ ref: thumbnailsRef }}
        open={index >= 0}
        index={index}
        on={{
          click: () => {
            (thumbnailsRef.current?.visible
              ? thumbnailsRef.current?.hide
              : thumbnailsRef.current?.show)?.();
          },
        }}
      />
      <div className={styles.photos} onClick={(event) => setImageViewer(true)}>
        <img
          className={styles.photosChild}
          alt=""
          src={truck?.images?.threeQuarterView || "/truck/no-image-icon.svg"}
          data-index="1"
        />
        <div className={styles.rectangleParent}>
          <img
            onClick={() => setOpen(true)}
            className={styles.frameChild}
            alt=""
            src={truck?.images?.front || "/truck/no-image-icon.svg"}
          />
          <div className={styles.rectangleGroup}>
            <img
              style={{ filter: "brightness(50%)" }}
              onClick={() => setOpen(true)}
              className={styles.frameItem}
              alt=""
              src={truck?.images?.passengerSide || "/truck/no-image-icon.svg"}
            />
            <div className={styles.photos1}>
              +{truck?.images?.passengerSide ? `${imageCount - 2} photos` : ""}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.peterbilt579Parent}>
          <div className={styles.peterbilt579}>
            {truck.year}{" "}
            {truck.manufacturer?.charAt(0).toUpperCase() +
              truck.manufacturer?.slice(1)}{" "}
            {truck?.model}
          </div>

          <div className={styles.vecicleCardrectangle1271}>
            <div className={styles.vecicleCardrectangle1271Child} />
          </div>
          <div className={styles.tandemTractor}>{truck.vehicleType}</div>
        </div>
        <div className={styles.peterbilt579Parent}>
          <span style={{ fontWeight: "600" }}>Location: </span>
          {truck?.state?.toUpperCase()},{" "}
          {truck.city?.charAt(0).toUpperCase() + truck.city?.slice(1)},{" "}
          {truck.zipCode}
        </div>
        <div className={styles.component21Parent}>
          {truck.enableForRent && (
            <>
              <div className={styles.component21}>
                <div className={styles.rent}>Rent</div>
              </div>
              <div className={styles.div}>/</div>
            </>
          )}
          {truck.enableForLease && (
            <>
              <div className={styles.component22}>
                <div className={styles.rent1}>Lease</div>
              </div>
              <div className={styles.div1}>/</div>
            </>
          )}
          {truck.enableForLeaseForPurchase && (
            <div className={styles.component19}>
              <div className={styles.sale}>Lease To Purchase</div>
            </div>
          )}
        </div>
        <div className={styles.heyThereThisContainer}>
          <span className={styles.heyThere}>
            {" "}
            {truck.description?.charAt(0).toUpperCase() +
              truck.description?.slice(1)}
          </span>
          <span className={styles.soHitMe}>
            {/* So hit me up if you're interested in giving her a spin! */}
          </span>
        </div>
        <i className={styles.updatedTueJune}>Updated: N/A</i>
        <div className={styles.frameInner} />
        <div className={styles.about}>About</div>
        <div className={styles.gridContainer}>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
              />
              <div className={`${styles.reefer} bodyType`}>
                {truck.vehicleSubtype}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.inputTextParent}>
            <div className={styles.inputText}>Length</div>
            <div className={styles.checkboxParent}>
              <CheckBox checked={truck.crewCab} text="Crew Cab" />

              {/* <CheckBox checked={false} text="Logistics Post" /> */}
              {/* <CheckBox checked={false} text="Auxiliary Power Unit" /> */}
            </div>
          </div>
          <div className={styles.inputTextGroup}>
            <div className={styles.inputText1}>Length</div>
            <div className={styles.checkboxGroup}>
              <CheckBox
                checked={truck.gps == "yes" ? true : false}
                text="GPS"
              />

              {/* <CheckBox checked={false} text="Storage Only" /> */}
              {/* <CheckBox checked={truck.ramp} text="Ramp" /> */}
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>Length</div>
            <div className={styles.checkboxContainer}>
              <CheckBox checked={truck.iftaCompliant} text="IFTA Compliant" />

              {/* <CheckBox checked={false} text="Multi-Temp" /> */}

              {/* <CheckBox checked={false} text="Bulkhead" /> */}
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>Length</div>
            <div className={styles.checkboxContainer}>
              <CheckBox
                checked={truck.dockHeight == "yes" ? true : false}
                text="Dock Height"
              />
              {/* <CheckBox checked={false} text="Multi-Temp" /> */}

              {/* <CheckBox checked={false} text="Bulkhead" /> */}
            </div>
          </div>
        </div>
        <div className={styles.rectangleDiv} />
        <div className={styles.general}>General</div>
        <div className={styles.frameDiv}>
          <div className={styles.tablesParent}>
            <div className={styles.tables1}>
              <div className={styles.text1}>Year</div>
            </div>
            <div className={styles.tables2}>
              <div className={styles.text2}>Manufacturer</div>
            </div>
            <div className={styles.tables3}>
              <div className={styles.text3}>Model</div>
            </div>
            <div className={styles.tables4}>
              <div className={styles.text4}>Mileage</div>
            </div>
            <div className={styles.tables5}>
              <div className={styles.text5}>Trailer Type</div>
            </div>
            <div className={styles.tables4}>
              <div className={styles.text4}>Chassis</div>
            </div>
          </div>
          <div className={styles.tablesGroup}>
            <div className={styles.tables7}>
              <div className={styles.text7}>{truck.year}</div>
            </div>
            <div className={styles.tables8}>
              <div className={styles.text8}>{truck.manufacturer}</div>
            </div>
            <div className={styles.tables9}>
              <div className={styles.text9}>{truck.model}</div>
            </div>
            <div className={styles.tables10}>
              <div className={styles.text10}>{truck.mileage}</div>
            </div>
            <div className={styles.tables11}>
              <div className={styles.text11}>{truck.trailerType}</div>
            </div>
            <div className={styles.tables10}>
              <div className={styles.text11}>{truck.chassis}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.usageRestrictionsParent}>
        <div className={styles.usageRestrictions}>Usage Restrictions</div>
        <div className={styles.tagContainer}>
          {/* <div className={styles.tag5}>
            <img
              className={styles.earnRevenueFromYourIdleFl5}
              alt=""
              src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
            />
            <div className={styles.reefer5}>Over 26K lbs GVWR</div>
          </div> */}
          <div className={styles.tag6}>
            <img
              className={styles.earnRevenueFromYourIdleFl6}
              alt=""
              src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
            />
            <div
              className={`${
                truck.allowTravelOutOfState == "yes"
                  ? styles.reefer
                  : styles.reefer6
              }`}
            >
              Can Travel Out of State
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ratesParent}>
        <div className={styles.rates}>Rates</div>
        <div className={styles.frameWrapper}>
          <div className={styles.tagParent1}>
            <div className={styles.tag7}>
              <img
                className={styles.earnRevenueFromYourIdleFl7}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
              />
              <div className={styles.reefer7}>${truck.rentPerDay} Daily</div>
            </div>
            <div className={styles.tag8}>
              <img
                className={styles.earnRevenueFromYourIdleFl8}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer8}>
                ${truck.rentPrepaidMileage}/mile
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className={styles.calculatorParent}>
        <div className={styles.calculator}>Calculator</div>
        <div className={styles.inputWithLabelParent}>
          <div className={styles.inputWithLabel}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Price per truck per day"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </div>
          <div className={styles.inputWithLabel1}>
            <FormControl fullWidth>
              <InputLabel>Duration</InputLabel>
              <Select
                // value={age}
                label="Duration"
                // onChange={handleChange}
              >
                <MenuItem value={10}>-</MenuItem>
                <MenuItem value={20}>-</MenuItem>
                <MenuItem value={30}>-</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={styles.inputWithLabelGroup}>
          <div className={styles.inputWithLabel2}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Percentage of transactions"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </div>
          <div className={styles.inputWithLabel3}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Maximum price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </div>
        </div>
        <div className={styles.allCalculationsArePresumedParent}>
          <div className={styles.allCalculationsAre}>
            All calculations are presumed and subject to change. So keep in mind
            that the numbers may not be 100% accurate.
          </div>
          <div className={styles.button}>
            <div className={styles.text14}>Estimate</div>
          </div>
        </div>
      </div>
    
      <div className={styles.ownerAboutParent}>
        <div className={styles.ownerAbout}>Owner about</div>
        <div className={styles.frameParent2}>
          <div className={styles.atWrapper}>
            <div className={styles.at}>AT</div>
          </div>
          <div className={styles.owner}>Owner</div>
          <div className={styles.div2}>·</div>
          <div className={styles.associatedWithRoybamParent}>
            <div className={styles.associatedWithRoybam}>
              associated with Truck4
            </div>
            <img
              className={styles.iconoutlinequestionMarkCir}
              alt=""
              src="/truck/iconoutlinequestionmarkcircle1.svg"
            />
          </div>
          <div className={styles.component20}>
            <img
              className={styles.rivipCrownLineIcon}
              alt=""
              src="/truck/rivipcrownline.svg"
            />
            <div className={styles.premium}>Premium</div>
          </div>
        </div>
        <div className={styles.businessHours10am6pmParent}>
          <div className={styles.businessHours10am}>
            Business Hours: 10am - 6pm
          </div>
          <div className={styles.tpineLeasing}>
            TPine Leasing - San Antonio, Converse, Texas 78109
          </div>
          <div className={styles.frameParent3}>
            <div className={styles.frameParent4}>
              <div className={styles.materialSymbolsstarParent}>
                <img
                  className={styles.materialSymbolsstarIcon}
                  alt=""
                  src="/truck/materialsymbolsstar1.svg"
                />
                <img
                  className={styles.materialSymbolsstarIcon1}
                  alt=""
                  src="/truck/materialsymbolsstar1.svg"
                />
                <img
                  className={styles.materialSymbolsstarIcon2}
                  alt=""
                  src="/truck/materialsymbolsstar1.svg"
                />
                <img
                  className={styles.materialSymbolsstarIcon3}
                  alt=""
                  src="/truck/materialsymbolsstar1.svg"
                />
                <img
                  className={styles.materialSymbolsstarIcon4}
                  alt=""
                  src="/truck/materialsymbolsstar1.svg"
                />
              </div>
              <div className={styles.div3}>5,0</div>
            </div>
            <div className={styles.reviews8Ratings}>4 reviews, 8 ratings</div>
          </div>
        </div>
      </div>
      <div className={styles.mapParent} id="map">
        <div className={styles.map}>Map</div>
        <div className={styles.heyThereThisContainer1}>
          <p
            className={styles.heyThere1}
          >Hey there!  This baby is a powerhouse, with a 6.7-liter V8 engine, four-wheel drive, and towing capacity of up to 15,000 pounds. Plus, it's got all the bells and whistles, including heated leather seats, 8.4-inch infotainment system, and backup camera.&nbsp;</p>
          <p
            className={styles.whetherYoureMoving1}
          >Whether you're moving cross-country or just need to haul some heavy stuff, my truck is the perfect ride for you.&nbsp;</p>
          <span className={styles.soHitMe1}>
            So hit me up if you're interested in giving her a spin!
          </span>
        </div>
        <div className={styles.theLocationIs}>
          the location is assumed, more details will be known after booking*
        </div>
        <div className={styles.rectangleContainer}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src="/truck/rectangle-804@2x.png"
          />
          <div className={styles.frameChild1} />
          <div className={styles.iconoutlinemapParent}>
            <img
              className={styles.iconoutlinemap}
              alt=""
              src="/truck/iconoutlinemap.svg"
            />
            <div className={styles.vehicleLocationParent}>
              <div className={styles.vehicleLocation}>Vehicle location</div>
              <div className={styles.californiaLosAngeles}>
                California, Los Angeles, 90001
              </div>
            </div>
            <div className={styles.button1}>
              <div className={styles.text15}>View on the map</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.calendarParent}>
        <div className={styles.calendar}>Calendar</div>
        <div className={styles.chooseAConvenient}>
          Choose a convenient rent date for you.
        </div>
        <div className={styles.calendarDateRangeParent}>
          <div className={styles.calendarDateRange}>
            <div className={styles.autoAddedFrame}>
              <div className={styles.calendarelementsdatesmonth}>
                <div className={styles.august2020}>August 2023</div>
                <img
                  className={styles.pxCarrotRight}
                  alt=""
                  src="/truck/20px--carrotright.svg"
                />
                <img
                  className={styles.pxCarrotLeft}
                  alt=""
                  src="/truck/20px--carrotleft.svg"
                />
              </div>
            </div>
            <div className={styles.calendarDays}>
              <div className={styles.calendarDaysChild} />
              <div className={styles.calendarDaysItem} />
              <div className={styles.justDay}>
                <div className={styles.justDayChild} />
                <div className={styles.day}>4</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf}>
                <div className={styles.mon}>Mon</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf1}>
                <div className={styles.tue}>Tue</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf2}>
                <div className={styles.wed}>Wed</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf3}>
                <div className={styles.thu}>Thu</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf4}>
                <div className={styles.fri}>Fri</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf5}>
                <div className={styles.sat}>Sat</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf6}>
                <div className={styles.sun}>Sun</div>
              </div>
              <div className={styles.calendarelementsdayempty}>
                <div className={styles.bg} />
                <div className={styles.day1}>30</div>
              </div>
              <div className={styles.calendarelementsdayempty1}>
                <div className={styles.bg1} />
                <div className={styles.day2}>31</div>
              </div>
              <div className={styles.calendarelementsdaydefault}>
                <div className={styles.day3}>1</div>
              </div>
              <div className={styles.calendarelementsdayselected}>
                <div className={styles.currentDay}>2</div>
              </div>
              <div className={styles.oday}>
                <b className={styles.day4}>3</b>
              </div>
              <div className={styles.chosenDay}>
                <div className={styles.day5}>4</div>
              </div>
              <div className={styles.calendarelementsdaydefault1}>
                <div className={styles.day6}>5</div>
              </div>
              <div className={styles.calendarelementsdaydefault2}>
                <div className={styles.day7}>12</div>
              </div>
              <div className={styles.calendarelementsdaydefault3}>
                <div className={styles.day8}>6</div>
              </div>
              <div className={styles.calendarelementsdaydefault4}>
                <div className={styles.day9}>7</div>
              </div>
              <div className={styles.calendarelementsdaydefault5}>
                <div className={styles.day10}>8</div>
              </div>
              <div className={styles.calendarelementsdaydefault6}>
                <div className={styles.day11}>9</div>
              </div>
              <div className={styles.calendarelementsdaydefault7}>
                <div className={styles.day12}>10</div>
              </div>
              <div className={styles.calendarelementsdaydefault8}>
                <div className={styles.day13}>11</div>
              </div>
              <div className={styles.calendarelementsdaydefault9}>
                <div className={styles.day14}>19</div>
              </div>
              <div className={styles.calendarelementsdaydefault10}>
                <div className={styles.day15}>13</div>
              </div>
              <div className={styles.calendarelementsdaydefault11}>
                <div className={styles.day16}>14</div>
              </div>
              <div className={styles.calendarelementsdaydefault12}>
                <div className={styles.day17}>15</div>
              </div>
              <div className={styles.calendarelementsdaydefault13}>
                <div className={styles.day18}>16</div>
              </div>
              <div className={styles.calendarelementsdaydefault14}>
                <div className={styles.day19}>17</div>
              </div>
              <div className={styles.calendarelementsdaydefault15}>
                <div className={styles.day20}>18</div>
              </div>
              <div className={styles.calendarelementsdaydefault16}>
                <div className={styles.day21}>26</div>
              </div>
              <div className={styles.calendarelementsdaydefault17}>
                <div className={styles.day22}>20</div>
              </div>
              <div className={styles.calendarelementsdaydefault18}>
                <div className={styles.day23}>21</div>
              </div>
              <div className={styles.calendarelementsdaydefault19}>
                <div className={styles.day24}>22</div>
              </div>
              <div className={styles.calendarelementsdaydefault20}>
                <div className={styles.day25}>23</div>
              </div>
              <div className={styles.calendarelementsdaydefault21}>
                <div className={styles.day26}>24</div>
              </div>
              <div className={styles.calendarelementsdaydefault22}>
                <div className={styles.day27}>25</div>
              </div>
              <div className={styles.calendarelementsdayempty2}>
                <div className={styles.bg2} />
                <div className={styles.day28}>1</div>
              </div>
              <div className={styles.calendarelementsdaydefault23}>
                <div className={styles.day29}>27</div>
              </div>
              <div className={styles.calendarelementsdaydefault24}>
                <div className={styles.day30}>28</div>
              </div>
              <div className={styles.calendarelementsdaydefault25}>
                <div className={styles.day31}>29</div>
              </div>
              <div className={styles.calendarelementsdaydefault26}>
                <div className={styles.day32}>30</div>
              </div>
              <div className={styles.calendarelementsdaydefault27}>
                <div className={styles.day33}>31</div>
              </div>
              <div className={styles.calendarelementsdayempty3}>
                <div className={styles.bg3} />
                <div className={styles.day34}>2</div>
              </div>
            </div>
          </div>
          <div className={styles.calendarMonth}>
            <div className={styles.autoAddedFrame1}>
              <div className={styles.calendarelementsdatesmonth1}>
                <div className={styles.august20201}>September 2023</div>
                <img
                  className={styles.pxCarrotRight1}
                  alt=""
                  src="/truck/20px--carrotright.svg"
                />
                <img
                  className={styles.pxCarrotLeft1}
                  alt=""
                  src="/truck/20px--carrotleft.svg"
                />
              </div>
            </div>
            <div className={styles.calendarDays1}>
              <div className={styles.justDay1}>
                <div className={styles.justDayItem} />
                <div className={styles.day35}>4</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf7}>
                <div className={styles.mon1}>Mon</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf8}>
                <div className={styles.tue1}>Tue</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf9}>
                <div className={styles.wed1}>Wed</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf10}>
                <div className={styles.thu1}>Thu</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf11}>
                <div className={styles.fri1}>Fri</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf12}>
                <div className={styles.sat1}>Sat</div>
              </div>
              <div className={styles.calendarelementsdatesdayOf13}>
                <div className={styles.sun1}>Sun</div>
              </div>
              <div className={styles.calendarelementsdayempty4}>
                <div className={styles.bg4} />
                <div className={styles.day36}>30</div>
              </div>
              <div className={styles.calendarelementsdayempty5}>
                <div className={styles.bg5} />
                <div className={styles.day37}>31</div>
              </div>
              <div className={styles.calendarelementsdaydefault28}>
                <div className={styles.day38}>1</div>
              </div>
              <div className={styles.calendarelementsdayselected1}>
                <div className={styles.currentDay1}>2</div>
              </div>
              <div className={styles.oday1}>
                <b className={styles.day39}>3</b>
              </div>
              <div className={styles.chosenDay1}>
                <div className={styles.day40}>4</div>
              </div>
              <div className={styles.calendarelementsdaydefault29}>
                <div className={styles.day41}>5</div>
              </div>
              <div className={styles.calendarelementsdaydefault30}>
                <div className={styles.day42}>12</div>
              </div>
              <div className={styles.calendarelementsdaydefault31}>
                <div className={styles.day43}>6</div>
              </div>
              <div className={styles.calendarelementsdaydefault32}>
                <div className={styles.day44}>7</div>
              </div>
              <div className={styles.calendarelementsdaydefault33}>
                <div className={styles.day45}>8</div>
              </div>
              <div className={styles.calendarelementsdaydefault34}>
                <div className={styles.day46}>9</div>
              </div>
              <div className={styles.calendarelementsdaydefault35}>
                <div className={styles.day47}>10</div>
              </div>
              <div className={styles.calendarelementsdaydefault36}>
                <div className={styles.day48}>11</div>
              </div>
              <div className={styles.calendarelementsdaydefault37}>
                <div className={styles.day49}>19</div>
              </div>
              <div className={styles.calendarelementsdaydefault38}>
                <div className={styles.day50}>13</div>
              </div>
              <div className={styles.calendarelementsdaydefault39}>
                <div className={styles.day51}>14</div>
              </div>
              <div className={styles.calendarelementsdaydefault40}>
                <div className={styles.day52}>15</div>
              </div>
              <div className={styles.calendarelementsdaydefault41}>
                <div className={styles.day53}>16</div>
              </div>
              <div className={styles.calendarelementsdaydefault42}>
                <div className={styles.day54}>17</div>
              </div>
              <div className={styles.calendarelementsdaydefault43}>
                <div className={styles.day55}>18</div>
              </div>
              <div className={styles.calendarelementsdaydefault44}>
                <div className={styles.day56}>26</div>
              </div>
              <div className={styles.calendarelementsdaydefault45}>
                <div className={styles.day57}>20</div>
              </div>
              <div className={styles.calendarelementsdaydefault46}>
                <div className={styles.day58}>21</div>
              </div>
              <div className={styles.calendarelementsdaydefault47}>
                <div className={styles.day59}>22</div>
              </div>
              <div className={styles.calendarelementsdaydefault48}>
                <div className={styles.day60}>23</div>
              </div>
              <div className={styles.calendarelementsdaydefault49}>
                <div className={styles.day61}>24</div>
              </div>
              <div className={styles.calendarelementsdaydefault50}>
                <div className={styles.day62}>25</div>
              </div>
              <div className={styles.calendarelementsdayempty6}>
                <div className={styles.bg6} />
                <div className={styles.day63}>1</div>
              </div>
              <div className={styles.calendarelementsdaydefault51}>
                <div className={styles.day64}>27</div>
              </div>
              <div className={styles.calendarelementsdaydefault52}>
                <div className={styles.day65}>28</div>
              </div>
              <div className={styles.calendarelementsdaydefault53}>
                <div className={styles.day66}>29</div>
              </div>
              <div className={styles.calendarelementsdaydefault54}>
                <div className={styles.day67}>30</div>
              </div>
              <div className={styles.calendarelementsdaydefault55}>
                <div className={styles.day68}>31</div>
              </div>
              <div className={styles.calendarelementsdayempty7}>
                <div className={styles.bg7} />
                <div className={styles.day69}>2</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      */}
      <div className={styles.tipsAboutTheRulesOfTakingParent}>
        <div className={styles.tipsAboutThe}>
          Tips about the rules of taking in rent
        </div>
        <div className={styles.instanceGroup}>
          <div className={styles.rectangleParent1}>
            <div className={styles.instanceChild} />
            <div className={styles.div4}>Truck Condition</div>
            <div className={styles.leaveTheTruck}>
              Leave the truck in the same condition in which it was received.
              Avoid damage and leave it clean and tidy.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore}>Read more</div>
          </div>
          <div className={styles.rectangleParent2}>
            <div className={styles.instanceItem} />
            <div className={styles.div4}>Additional charges</div>
            <div className={styles.leaveTheTruck}>
              Make sure that you do not exceed the mileage limits specified in
              the rental agreement. Exceeding the limit may result in additional
              costs and penalties.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore1}>Read more</div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.instanceInner} />
            <div className={styles.div4}>Rules of canceling</div>
            <div className={styles.leaveTheTruck}>
              Make sure that you returned the truck in accordance with the
              rental agreement. Delays may result in additional fines or
              restrictions in the future.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore2}>Read more</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotosParent;

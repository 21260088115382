import { RootState } from "@/src/store/types";
import { FunctionComponent, useCallback } from "react";
import styles from "./css/PricingPagesTier1.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export interface AddTruckFirstPageProps {
  onNextButtonClick: () => void;
}

const PricingPagesTier1: FunctionComponent<AddTruckFirstPageProps> = ({
  onNextButtonClick,
}) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const onTheLogoMakerBuiltFor1000Click = useCallback(() => {}, []);
  const navigate = useNavigate();

  const handleNextClick = () => {
    if (
      `${localStorage.getItem("accessToken")}` !== "" &&
      `${localStorage.getItem("refreshToken")}` !== ""
    ) {
      onNextButtonClick();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className={styles.pricingPagestier}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.theLogoMakerBuiltFor1000Parent}>
            <Link to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'} >
              <img
                className={styles.theLogoMakerBuiltFor1000}
                alt=""
                src="/logos/Truck4 logo-1.png"
                onClick={() => {
                  window.location.href = "/#/main";
                }}
              />
            </Link>
            <img
              className={styles.shapesIcon}
              loading="eager"
              alt=""
              src="/generics/shapes-1.svg"
            />
          </div>
        </div>
        <div className={styles.turnYourTruck}>
          { currentUser.role == "driver" ? "As a driver you cannot add vehicle, please request for role switch as vehicle-owner and add vehicle" : "Turn your truck into a stable income — rent it out with us!"}
        </div>
      </div>
      <div
        className={styles.supportingText}
      >Lorem ipsum dolor sit amet consectetur, adipisicing elit.&nbsp;</div>
      <div className={styles.pricingPagestierChild} />
      <div className={styles.content}>
        {/* <div className={styles.features}>
          <div className={styles.pricingPagesfeature}>
            <img
              className={styles.checkIcon}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature1}>
            <img
              className={styles.checkIcon1}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text1}>
              Sapiente libero doloribus modi nostrum
            </div>
          </div>
          <div className={styles.pricingPagesfeature2}>
            <img
              className={styles.checkIcon2}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text2}>
              Vel ipsa esse repudiandae excepturi
            </div>
          </div>
          <div className={styles.pricingPagesfeature3}>
            <img
              className={styles.checkIcon3}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text3}>
              Vel ipsa esse repudiandae excepturi
            </div>
          </div>
          <div className={styles.pricingPagesfeature4}>
            <img
              className={styles.checkIcon4}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text4}>
              Itaque cupiditate adipisci quibusdam
            </div>
          </div>
          <div className={styles.pricingPagesfeature5}>
            <img
              className={styles.checkIcon5}
              loading="eager"
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text5}>
              Itaque cupiditate adipisci quibusdam
            </div>
          </div>
          <div className={styles.pricingPagesfeature6}>
            <img
              className={styles.checkIcon6}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text6}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature7}>
            <img
              className={styles.checkIcon7}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text7}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature8}>
            <img
              className={styles.checkIcon8}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text8}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature9}>
            <img
              className={styles.checkIcon9}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text9}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature10}>
            <img
              className={styles.checkIcon10}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text10}>Pariatur quod similique</div>
          </div>
          <div className={styles.pricingPagesfeature11}>
            <img
              className={styles.checkIcon11}
              alt=""
              src="/generics/check.svg"
            />
            <div className={styles.text11}>Pariatur quod similique</div>
          </div>
        </div> */}
        <button className={styles.button} onClick={handleNextClick}>
          <div className={styles.text12}>{ currentUser.role == "driver" ? "Switch role and add vehicle" : "Add a vehicle"}</div>
        </button>
      </div>
    </div>
  );
};

export default PricingPagesTier1;

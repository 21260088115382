import React from "react";
import styles from "./ProcessSection.module.css";

interface ProcessStep {
  title: string;
  description: string;
}

const processSteps: ProcessStep[] = [
  {
    title: "Search and Select a Truck",
    description:
      "Select a truck on the website and contact the lessor to discuss rental terms.",
  },
  {
    title: "Details and Booking",
    description:
      "Discuss and confirm rental conditions, including duration and cost.",
  },
  {
    title: "Confirmation and Payment",
    description: "Complete the booking on the website and make the payment.",
  },
  {
    title: "Pick Up the Keys",
    description:
      "Meet with the lessor, sign the rental agreement, and receive the keys to the truck.",
  },
];

const ProcessSection: React.FC = () => {
  return (
    <section className={styles.processSection}>
      <div className={styles.header}>
        <span className={styles.eyebrow}>Processes</span>
        <h2 className={styles.title}>We streamline complex processes</h2>
        <p className={styles.description}>
          Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
          Malesuada adipiscing sagittis vel nulla nec. Malesuada adipiscing
          sagittis vel nulla nec.
        </p>
        <a href="#" className={styles.link}>
          How we work
          <span style={{ marginLeft: "10px" }}>›</span>
        </a>
      </div>
      <div className={styles.stepsContainer}>
        {processSteps.map((step, index) => (
          <div key={index} className={styles.step}>
            <div className={styles.stepIcon}>
              <div className={styles.iconBackground} />
              <div className={styles.dashedline}></div>
            </div>
            <div className={styles.stepContent}>
              <h3 className={styles.stepTitle}>{step.title}</h3>
              <p className={styles.stepDescription}>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProcessSection;

import React, { createContext, useState, ReactNode, useContext } from 'react';

interface GlobalStateType {
  searchString: string;
  setSearchString: (s: string) => void;
  searchByLocation: string;
  setSearchByLocation: (l: string) => void;
}

const GlobalContext = createContext<GlobalStateType | undefined>(undefined);

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [searchString, setSearchString] = useState<string>('');
  const [searchByLocation, setSearchByLocation] = useState<string>('');

  return (
    <GlobalContext.Provider value={{ searchString, setSearchString, searchByLocation, setSearchByLocation }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

import React, { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { validateCurrentUser } from "./CheckAuth";
import Loader from "../Loader/Loader";
import { errorToast } from "../Toast/ToastMessage";
import { useSelector } from "react-redux";

interface NoAuthRestrictedRouteProps {
  cmp: React.ComponentType<any>;
}

const NoAuthRestrictedRoute: React.FC<NoAuthRestrictedRouteProps> = ({
  cmp,
}: NoAuthRestrictedRouteProps): ReactElement => {
  const Cmp = cmp;
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      navigate("/admin/truck_management");
    } else {
      setLoading(false);
    }
  }, [navigate]);

  return (
    <div>
      {/* <Topnav /> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Cmp />
        </>
      )}
    </div>
  );
};

export default NoAuthRestrictedRoute;

import React from "react";

const MapComponent = (props: any) => {
  return (
    <div>
      <div className={props.styles.buttonContainer}>
        <div className={props.styles.button4}>
          <img
            className={props.styles.iconsolidchevronLeft}
            alt=""
            src="/generics/iconsolidchevronleft.svg"
          />
        </div>
        <div className={props.styles.groupDiv}>
          <div className={props.styles.button5}>
            <img
              className={props.styles.iconsolidchevronLeft}
              alt=""
              src="/generics/iconoutlineplus.svg"
            />
          </div>
          <div className={props.styles.button6}>
            <img
              className={props.styles.iconsolidchevronLeft}
              alt=""
              src="/generics/iconsolidminus.svg"
            />
          </div>
        </div>
        <img
          className={props.styles.image76Icon}
          alt=""
          src="/generics/image-76@2x.png"
        />
        <div className={props.styles.component13}>
          <div className={props.styles.text}>120$</div>
        </div>
        <div className={props.styles.component14}>
          <div className={props.styles.text}>130$</div>
        </div>
        <div className={props.styles.component15}>
          <div className={props.styles.text}>80$</div>
        </div>
        <div className={props.styles.component16}>
          <div className={props.styles.text}>115$</div>
        </div>

        {/* Overlay for "Coming Soon" */}
        <div className={props.styles.overlay}>
          <div className={props.styles.overlayText}>COMING SOON</div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;

import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/AddTruckSpecs.module.css";
import styles2 from "../../css/Registration_Driver/AddOrganization.module.css";
import styled from "styled-components";
import styles3 from "./css/AddTruckTypeAndDetails.module.css";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { RootStateTruckAdd, TruckRegistrationData } from "@/src/store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { cargoOptions } from "./selectValues";
import { errorToast } from "../../components/Toast/ToastMessage";

export type PropsFromReduxAddTruckSpecs = ConnectedProps<
  typeof connectorAddTruckSpecs
>;

export type AddTruckSpecs = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  idToUpdate?: TruckRegistrationData;
  doorType: string;
  setDoorType: React.Dispatch<React.SetStateAction<string>>;
  liftGateType: string;
  setLiftGateType: React.Dispatch<React.SetStateAction<string>>;
  dockHeight: string;
  setDockHeight: React.Dispatch<React.SetStateAction<string>>;
  allowTravelOutOfState: boolean;
  setAllowTravelOutOfState: React.Dispatch<React.SetStateAction<boolean>>;
  gps: boolean;
  setGps: React.Dispatch<React.SetStateAction<boolean>>;
  chassis: string;
  setChassis: React.Dispatch<React.SetStateAction<string>>;
  transmissionType: string;
  setTransmissionType: React.Dispatch<React.SetStateAction<string>>;
  lengthValue: any;
  setLengthValue: React.Dispatch<React.SetStateAction<any>>;
  cargoValue: string;
  setCargoValue: React.Dispatch<React.SetStateAction<string>>;
  cargoSecurementTrack: string;
  selectedTruckSubtype2: string;
  selectedTruckSubtype: string;
  setCargoSecurementTrack: React.Dispatch<React.SetStateAction<string>>;
} & PropsFromReduxAddTruckSpecs;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckSpecs = connect(mapStateToProps, mapDispatchToProps);

const AddTruckSpecs: FunctionComponent<AddTruckSpecs> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  idToUpdate,
  doorType,
  setDoorType,
  liftGateType,
  setLiftGateType,
  dockHeight,
  setDockHeight,
  allowTravelOutOfState,
  setAllowTravelOutOfState,
  gps,
  setGps,
  chassis,
  setChassis,
  transmissionType,
  setTransmissionType,
  lengthValue,
  setLengthValue,
  cargoValue,
  setCargoValue,
  cargoSecurementTrack,
  setCargoSecurementTrack,
  selectedTruckSubtype2,
  selectedTruckSubtype,
}) => {
  const reduxState = useSelector((state: any) => state);
  const handleNextClick = (isSkippable?: boolean) => {
    if (!isSkippable) {
      if (validateForm()) {
        if (
          dockHeight &&
          !(Number(dockHeight) >= 36 && Number(dockHeight) <= 58)
        ) {
          errorToast("dock height should be between 36 to 58 inches");
          return;
        } else {
          onNextButtonClick();
        }
      } else {
        errorToast("Please fill all fields");
      }
    } else {
      onNextButtonClick();
    }
  };

  const navigate = useNavigate();
  const types = ["boxtruck", "dry van", "reefer", "box truck", "dryvan"];
  const verifyDoorType = types.some((type) =>
    selectedTruckSubtype2.includes(type)
  );

  const truckAddRegistrationDataRedux = useSelector(
    (state: RootStateTruckAdd) => state
  );
  const [requiredField, setRequiredField] = useState<string>("");

  const handleDoorType = (state: string) => {
    setDoorType(state);
    const newDoorType = state;
    // setDoorType(newDoorType);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      doorType: newDoorType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLiftGateType = (state: string) => {
    setLiftGateType(state);
    const newLiftGateType = state;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      liftGateType: newLiftGateType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleAllowTravelOutOfState = (state: boolean) => {
    const newAllowTravelOutOfState = state;
    setAllowTravelOutOfState(newAllowTravelOutOfState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      allowTravelOutOfState: newAllowTravelOutOfState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleGps = (state: boolean) => {
    setGps(state);
    const newGps = state;
    setGps(newGps);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      GPS: newGps,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleChassis = (state: string) => {
    setChassis(state);
    const newChassis = state;
    setChassis(newChassis);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      chassis: newChassis,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTransmissionType = (state: string) => {
    setTransmissionType(state);
    const newTransmissionType = state;
    setTransmissionType(newTransmissionType);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      transmissionType: newTransmissionType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [isCargoDropdown, setIsCargoDropdown] = useState<boolean>(false);

  const toggleDropdown = () => {
    closeDropDowns();
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const toggleCargoDropdown = () => {
    closeDropDowns();
    setIsCargoDropdown(!isCargoDropdown);
  };

  const closeDropDowns = () => {
    setIsDropdownOpen1(false);
    setIsCargoDropdown(false);
  };

  const lengthOptions =
    selectedTruckSubtype === "boxtruck"
      ? Array.from({ length: 22 }, (_, i) => (i + 5).toString())
      : ["40", "45", "48", "53"];

  const filteredCargo = cargoOptions.filter((option) =>
    option.toLowerCase().includes(cargoValue.toLowerCase())
  );

  const handleLength = (option: string) => {
    setIsDropdownOpen1(!isDropdownOpen1);
    const newLength = {length: { feet: Number(option)}}
    setLengthValue(newLength);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      length: {...truckAddRegistrationData?.length, feet: Number(option)}
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleCargoValue = (option: string) => {
    setIsCargoDropdown(!isCargoDropdown);
    const newCargo = option;
    setCargoSecurementTrack(newCargo);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      cargoSecurementTrack: newCargo,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLengthValue(value);
    setIsDropdownOpen1(true);
  };

  const handleCargoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCargoSecurementTrack(value);
    setIsCargoDropdown(true);
  };

  const handleDockHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDockHeight = e.target.value;

    if (isNaN(Number(newDockHeight))) {
      setDockHeight("");
      return;
    }

    setDockHeight(newDockHeight);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      dockHeight: newDockHeight,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const onInputTextClick = useCallback(() => {
    navigate("/register-a-truck-or-edit-1-page-for-user");
  }, [navigate]);

  const validateForm = () => {
    if (
      !idToUpdate &&
      (!lengthValue ||
        !allowTravelOutOfState ||
        (!transmissionType && !selectedTruckSubtype.includes("trailer")))
    ) {
      const emptyField = !lengthValue
        ? "lengthValue"
        : !allowTravelOutOfState
        ? "allowTravelOutOfState"
        : !transmissionType
        ? "transmissionType"
        : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log(idToUpdate);
    console.log(reduxState.truckAddRegistrationData);
    if (
      reduxState.truckAddRegistrationData?.vehicleType === "trailer" ||
      reduxState.truckAddRegistrationData?.vehicleType === "truck" ||
      reduxState.truckAddRegistrationData?.vehicleType === "boxtruck" ||
      idToUpdate?.vehicleType === "trailer" ||
      idToUpdate?.vehicleType === "boxtruck" ||
      idToUpdate?.vehicleType === "truck"
    ) {
    } else {
      handleNextClick(true);
    }
  }, []);

  return (
    <>
      <div className={styles.registerATruckOrEdit3P}>
        <div className={styles.registerATruckOrEdit3PChild} />
        <div className={styles.component35Parent}>
          <div className={styles.component35}>
            <div className={styles.component35Inner}>
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.text2}>Back</div>
                </div>
                <div className={styles.inputText} onClick={onInputTextClick}>
                  3 of 6
                </div>
              </div>
            </div>
            <div className={styles.userNameParent}>
              <div className={styles.userName} onClick={() => {}}>
                Add Your Specs
              </div>
              <div className={styles.inputText1}>
                Sign up with your work Google account or use the form.
              </div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.textButtonGroup}>
              <div className={styles.textButton1}>
                <img className={styles.icon1} alt="" src="/generics/icon.svg" />
                <div className={styles.text3}>Back&nbsp;</div>
              </div>
              <div className={styles.frameGroup}>
                {verifyDoorType && (
                  <>
                    <div className={styles.inputWithLabelParent}>
                      <div className={styles.inputWithLabel}>
                        <div
                          className={styles.inputText2}
                          style={{
                            color: requiredField == "doorType" ? "red" : "",
                          }}
                        >
                          Door type*
                        </div>
                        <div className={styles.basicInput}>
                          <div className={styles.inputText2}>
                            First name&nbsp;
                          </div>
                        </div>
                      </div>
                      <div className={styles.tagParent}>
                        <div
                          className={`${styles.tag} ${
                            doorType === "swing" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleDoorType("swing")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Swing</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            doorType === "roll" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleDoorType("roll")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl1}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer1}>Roll</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputWithLabelGroup}>
                      <div className={styles.inputWithLabel1}>
                        <div
                          className={styles.inputText2}
                          style={{
                            color: requiredField == "liftGateType" ? "red" : "",
                          }}
                        >
                          Lift gate*
                        </div>
                        <div className={styles.basicInput1}>
                          <div className={styles.inputText2}>
                            First name&nbsp;
                          </div>
                        </div>
                      </div>
                      <div className={styles.tagGroup}>
                        <div
                          className={`${styles.tag} ${
                            liftGateType.includes("railgate")
                              ? styles.selectedTag
                              : ""
                          } hoverButtons`}
                          onClick={() => handleLiftGateType("railgate")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl2}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer2}>Rail gate</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            liftGateType.includes("truckunder")
                              ? styles.selectedTag
                              : ""
                          } hoverButtons`}
                          onClick={() => handleLiftGateType("truckunder")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl3}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer3}>Truck under</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            liftGateType.includes("noliftgate")
                              ? styles.selectedTag
                              : ""
                          } hoverButtons`}
                          onClick={() => handleLiftGateType("noliftgate")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl4}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer4}>No lift gate</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.inputWithLabelContainer}>
                  {(selectedTruckSubtype.includes("boxtruck") ||
                    selectedTruckSubtype.includes("trailer")) && (
                    <div className={styles.inputWithLabel2}>
                      <div className={styles.inputText2}>Length*</div>
                      <div
                        className={styles.dropdown1}
                        onClick={toggleDropdown}
                        style={{
                          border:
                            requiredField == "lengthValue"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <input
                          className={""}
                          placeholder="Length (feet)"
                          type="text"
                          value={lengthValue?.length?.feet || idToUpdate?.length?.feet}
                          onChange={handleLengthChange}
                        />

                        <StyledButton
                          className={styles2.dropdownButton}
                          onKeyDown={() => {}}
                          role="button"
                          style={{ position: "absolute", top: "1vh" }}
                        >
                          <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                        </StyledButton>
                        {isDropdownOpen1 && (
                          <Container>
                            {lengthOptions.map((option, index) => (
                              <Item
                                key={index}
                                className={`hoverDropdown ${
                                  lengthValue == option ? "text-theme" : ""
                                } `}
                                onClick={() => handleLength(option)}
                              >
                                {option}
                              </Item>
                            ))}
                          </Container>
                        )}
                      </div>
                    </div>
                    )
                  }
                    {
                      !verifyDoorType && !(selectedTruckSubtype == 'truck') && (
                        <div className={styles.inputWithLabel3}>
                          <div className={styles.inputText2}>
                            Cargo Securement track
                          </div>
                          <div
                            className={styles.dropdown1}
                            onClick={toggleCargoDropdown}
                            style={{
                              border:
                                requiredField == "cargoSecurementTrack"
                                  ? "1px solid red"
                                  : "",
                            }}
                          >
                            <input
                              className={""}
                              placeholder="Cargo Securement track"
                              type="text"
                              value={
                                cargoSecurementTrack ||
                                idToUpdate?.cargoSecurementTrack
                              }
                              onChange={handleCargoChange}
                            />
      
                            <StyledButton
                              className={styles2.dropdownButton}
                              onKeyDown={() => {}}
                              role="button"
                              style={{ position: "absolute", top: "1vh" }}
                            >
                              <FontAwesomeIcon key={"2"} icon={faChevronDown} />
                            </StyledButton>
                            {isCargoDropdown && (
                              <Container>
                                {filteredCargo.map((option, index) => (
                                  <Item
                                    key={index}
                                    className={`hoverDropdown ${
                                      cargoSecurementTrack == option
                                        ? "text-theme"
                                        : ""
                                    } `}
                                    onClick={() => handleCargoValue(option)}
                                  >
                                    {option}
                                  </Item>
                                ))}
                              </Container>
                            )}
                          </div>
                        </div>
                      )
                    }
                </div>
              </div>
              <div className={styles3.frameChild} />
              {selectedTruckSubtype.includes("boxtruck") && (
                <div
                  className={styles.inputWithLabel3}
                  style={{ width: "100%" }}
                >
                  <div className={styles3.inputText21}>Dock Height</div>
                  <div className={styles3.basicInput4}>
                    <input
                      className={styles3.inputText22}
                      placeholder="Dock height (inches)"
                      type="text"
                      value={
                        dockHeight ||
                        (idToUpdate?.dockHeight && isNaN(Number(dockHeight))
                          ? idToUpdate?.dockHeight
                          : "")
                      }
                      onChange={handleDockHeight}
                      maxLength={25}
                    />
                  </div>
                </div>
              )}
              <div className={styles.frameContainer}>
                <div className={styles.inputWithLabelParent1}>
                  <div className={styles.inputWithLabel5}>
                    <div
                      className={styles.inputText2}
                      style={{
                        color:
                          requiredField == "allowTravelOutOfState" ? "red" : "",
                      }}
                    >
                      Renter can travel out of state*
                    </div>
                    <div className={styles.basicInput3}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagParent1}>
                    <div
                      className={`${styles.tag} ${
                        allowTravelOutOfState
                          ? styles.selectedTag
                          : ""
                      } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState(true)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl7}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer7}>Yes</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        !allowTravelOutOfState ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState(false)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl8}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer8}>No</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent2}>
                  <div className={styles.inputWithLabel6}>
                    <div
                      className={styles.inputText2}
                      style={{ color: requiredField == "gps" ? "red" : "" }}
                    >
                      GPS
                    </div>
                    <div className={styles.basicInput4}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagParent2}>
                    <div
                      className={`${styles.tag} ${
                        gps ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleGps(true)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl9}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer9}>Yes</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        !gps ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleGps(false)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl10}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer10}>No</div>
                    </div>
                  </div>
                </div>
                {!selectedTruckSubtype.includes("trailer") && (
                  <div className={styles.inputWithLabelParent3}>
                    <div className={styles.inputWithLabel7}>
                      <div
                        className={styles.inputText2}
                        style={{
                          color:
                            requiredField == "transmissionType" ? "red" : "",
                        }}
                      >
                        Transmission Type
                      </div>
                    </div>
                    <div className={styles.tagParent3}>
                      <div
                        className={`${styles.tag} ${
                          transmissionType === "automatic"
                            ? styles.selectedTag
                            : ""
                        } hoverButtons`}
                        onClick={() => handleTransmissionType("automatic")}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl11}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer11}>Automatic</div>
                      </div>
                      <div
                        className={`${styles.tag} ${
                          transmissionType === "manual"
                            ? styles.selectedTag
                            : ""
                        } hoverButtons`}
                        onClick={() => handleTransmissionType("manual")}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl12}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer12}>Manual</div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedTruckSubtype == "trailer" && (
                  <>
                    <div className={styles.inputWithLabelParent4}>
                      <div className={styles.inputWithLabel8}>
                        <div
                          className={styles.inputText2}
                          style={{
                            color: requiredField == "chassis" ? "red" : "",
                          }}
                        >
                          Transmission Type
                        </div>
                      </div>
                      <div className={styles.tagParent4}>
                        <div
                          className={`${styles.tag} ${
                            chassis === "2x2" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleChassis("2x2")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl13}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer13}>2x2</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            chassis === "2x4" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleChassis("2x4")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl14}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer14}>2x4</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            chassis === "2x6" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleChassis("2x6")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl15}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer15}>2x6</div>
                        </div>
                        <div
                          className={`${styles.tag} ${
                            chassis === "6x4" ? styles.selectedTag : ""
                          } hoverButtons`}
                          onClick={() => handleChassis("6x4")}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl16}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer16}>6x4</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.button4}>
                <div className={styles.text4}>Save as a draft</div>
              </div>
              <div className={styles.buttonParent1}>
                <div
                  className={`${styles.button5} hoverButtons`}
                  onClick={onBackButtonClick}
                >
                  <div className={styles.text5}>Back</div>
                </div>
                <div
                  className={`${styles.button6} hoverButtons`}
                  onClick={() => handleNextClick(false)}
                >
                  <div className={styles.text6}>Next</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.celesClubPVidiGruzovikhMaParent}>
          <img
            className={styles.celesClubPVidiGruzovikhMaIcon}
            alt=""
            src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
          />
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>
              Hire internationally with complete confidence
            </div>
            <div className={styles.inputText2}>
              We'll handle your worldwide compliance, payroll, and benefits, so
              you can fast-track your international expansion.
            </div>
          </div>
          <img
            className={styles.stockPhotoBigRigGrayBonneIcon}
            alt=""
            src="/generics/stockphotobigriggraybonnetsemitruckwithhighcabandlongrefrigeratorsemitrailertransporting2263359175removebgpreview@2x.png"
          />
          <img
            className={styles.theLogoMakerBuiltFor10001}
            alt=""
            src="/logos/Truck4 logo-1.png"
            onClick={() => {
              window.location.href = "/#/main";
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connectorAddTruckSpecs(AddTruckSpecs);

const StyledButton = styled.button`
  border: 0;
  right: 5%;
  background-color: transparent;
`;

const Container = styled.div`
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

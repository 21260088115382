import { FunctionComponent, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styles from "./ProductPage.module.css";
import PhotosParent from "./PhotosParent";
import InfoContainer from "./InfoContainer";
import axios from "axios";
import ImageViewer from "./ImageViewer";
import Footer from "../Add_Truck/Footer";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  REACT_APP_TRUCK_API_BASE_URL,
  REACT_APP_ORG_API_BASE_URL,
} from "../../utils/UrlConstants";
import { useReactToPrint } from "react-to-print";

type ImageUrl = string;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "gray" : "gray",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

interface Props {
  window?: () => Window;
}

const ProductPage: FunctionComponent = (props: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [imageViewer, setImageViewer] = useState<boolean>(false);
  const [images, setImages] = useState<ImageUrl[]>([]);
  const [ownerMail, setOwnerMail] = useState<string>("");
  const [ownerNumber, setOwnerNumber] = useState<string>("");
  const [truck, setTruck] = useState({});
  const [imageCount, setImageCount] = useState<number>(0);
  const { id } = useParams();

  //Mobile Drawer
  const { window } = props;
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const fetchTruck = async () => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response.data.truck[0]);
      console.log("response ^^");
      setTruck(response.data.truck[0]);
      setImageCount(Object.keys(response.data.truck[0]?.images).length);
      const fetchedImages: ImageUrl[] = Object.values(
        response.data.truck[0].images
      );
      const updatedImages: ImageUrl[] = [...fetchedImages];
      console.log(fetchedImages);
      console.log("Updated Images");
      setImages(updatedImages);
      setOwnerMail(response.data.truck[0].userDetails.email);
      setOwnerNumber(response.data.truck[0].userDetails.phone);
      fetchOrganization(response.data.truck[0].organization);
    } catch (error) {
      console.error(error, error);
    }
  };

  const fetchOrganization = async (id: string) => {
    console.log("id vvvv");
    console.log(id);
    const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response);
      if (response.status === 200) {
        console.log("Product page vvvv");
        console.log(response.data.organization);
        // setOwnerMail(response.data.organization.email);
        // setOwnerNumber(response.data.organization.phone);
      }
    } catch (error) {
      console.error("Error fetching organization:", error);
    }
  };

  useEffect(() => {
    fetchTruck();
    console.log("Owner truck:");
    console.log(truck);
  }, []);

  return (
    <>
      {imageViewer ? (
        <ImageViewer images={images} setImageViewer={setImageViewer} />
      ) : (
        <>
          <div className={styles.productPage} ref={contentRef}>
            <div className={styles.tabExampleLightDesktopGroup}>
              <div className={styles.tabExampleLightDesktop1}>
                <div className={styles.tabs1}>
                  <div className={styles.desktopTab7}>
                    <div className={styles.tabItemWrapper}>
                      <div className={styles.tabItem}>Overview</div>
                    </div>
                    <div className={styles.desktopTabChild5} />
                  </div>
                  {/* <div className={styles.desktopTab8}>
                    <div className={styles.tabItemContainer}>
                      <span className={styles.tabItem1}>Reviews</span>
                    </div>
                    <div className={styles.desktopTabChild6} />
                  </div>
                  <div className={styles.desktopTab9}>
                    <div className={styles.tabItemFrame}>
                      <div className={styles.tabItem2}>Amenities</div>
                    </div>
                    <div className={styles.desktopTabChild7} />
                  </div>
                  <div className={styles.desktopTab10}>
                    <div className={styles.tabItemWrapper1}>
                      <div className={styles.tabItem3}>Availability</div>
                    </div>
                    <div className={styles.desktopTabChild8} />
                  </div>
                  <div className={styles.desktopTab11}>
                    <div className={styles.tabItemWrapper2}>
                      <a>
                        <div className={styles.tabItem4}>Map</div>
                      </a>
                    </div>
                    <div className={styles.desktopTabChild9} />
                  </div>
                  <div className={styles.desktopTab12}>
                    <div className={styles.tabItemWrapper3}>
                      <div className={styles.tabItem5}>Policies</div>
                    </div>
                    <div className={styles.desktopTabChild10} />
                  </div> */}
                  <div className={styles.desktopTab13}>
                    <div className={styles.tab7Container}>
                      <div className={styles.tab71}>Tab 7</div>
                    </div>
                    <div className={styles.desktopTabChild11} />
                  </div>
                </div>
                <div className={styles.underLine1} />
              </div>
              <div className={styles.buttonContainer}>
                <div className={styles.button7}>
                  <img
                    className={styles.iconoutlineprinter}
                    alt=""
                    src="/truck/iconoutlineprinter.svg"
                  />
                  <div
                    className={styles.text22}
                    onClick={() => reactToPrintFn()}
                  >
                    Print
                  </div>
                </div>
                <div className={styles.button8}>
                  <img
                    className={styles.iconoutlineshare}
                    alt=""
                    src="/truck/iconoutlineshare.svg"
                  />
                  <div className={styles.text23}>Share</div>
                </div>
                <div className={styles.button9}>
                  <img
                    className={styles.iconoutlineheart}
                    alt=""
                    src="/truck/iconoutlineheart.svg"
                  />
                  <div className={styles.text24}>Save</div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.mainContainer}>
                <PhotosParent
                  setImageViewer={setImageViewer}
                  truck={truck}
                  imageCount={imageCount}
                />
                <div className={styles.drawer}>
                  {isMobile ? (
                    <>
                      <Root>
                        <CssBaseline />
                        <Global
                          styles={{
                            ".MuiDrawer-root > .MuiPaper-root": {
                              height: `calc(93% - ${drawerBleeding}px)`,
                              overflow: "visible",
                            },
                          }}
                        />
                        {/* <Box sx={{ textAlign: "center" }}>
                          <Button onClick={() => toggleDrawer(true)} style={{color: 'black'}}>
                            Contact
                          </Button>
                        </Box> */}
                        <SwipeableDrawer
                          anchor="bottom"
                          open={open}
                          onClose={toggleDrawer(false)}
                          onOpen={toggleDrawer(true)}
                          swipeAreaWidth={drawerBleeding}
                          disableSwipeToOpen={false}
                          ModalProps={{
                            keepMounted: true,
                          }}
                        >
                          <StyledBox
                            sx={{
                              position: "absolute",
                              top: -drawerBleeding,
                              // background: "red",
                              paddingTop: "8px",
                              boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.75)",
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              visibility: "visible",
                              right: 0,
                              left: 0,
                              paddingBottom: "10%",
                            }}
                          >
                            <Puller />
                            <Typography
                              sx={{
                                p: 2,
                                textAlign: "center",
                                color: "text.secondary",
                              }}
                            >
                              Contact Owner
                            </Typography>

                            <InfoContainer
                              truck={truck}
                              mail={ownerMail}
                              ownerNumber={ownerNumber}
                            />
                            <Skeleton variant="rectangular" height="100%" />
                          </StyledBox>
                        </SwipeableDrawer>
                      </Root>
                    </>
                  ) : (
                    <InfoContainer
                      truck={truck}
                      mail={ownerMail}
                      ownerNumber={ownerNumber}
                    />
                  )}
                </div>
              </div>

              <div className={styles.footer}>
                <div className={styles.container}>
                  <div className={styles.container1}>
                    <div className={styles.links}>
                      <div className={styles.footerssimpleLink}>
                        <div className={styles.text25}>Terms & Conditions</div>
                      </div>
                      <div className={styles.footerssimpleLink1}>
                        <div className={styles.text26}>Privacy Policy</div>
                      </div>
                      <div className={styles.footerssimpleLink2}>
                        <div className={styles.text27}>Help Center</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.footersocialLinks}>
                    <div className={styles.footerssocialLink}>
                      <img
                        className={styles.footerssocialIcon}
                        alt=""
                        src="/truck/footerssocial-icon.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink1}>
                      <img
                        className={styles.footerssocialIcon1}
                        alt=""
                        src="/truck/footerssocial-icon-1.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink2}>
                      <img
                        className={styles.footerssocialIcon2}
                        alt=""
                        src="/truck/footerssocial-icon-2.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink3}>
                      <img
                        className={styles.footerssocialIcon3}
                        alt=""
                        src="/truck/footerssocial-icon-3.svg"
                      />
                    </div>
                    <div className={styles.footerssocialLink4}>
                      <img
                        className={styles.footerssocialIcon4}
                        alt=""
                        src="/truck/footerssocial-icon-4.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.copyright}>
                    © 2023 Truck4, Inc. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={styles.toast}>
              <div className={styles.toastMessage}>Driver view mode</div>
              <div className={styles.button14}>
                <div className={styles.text31}>Close</div>
              </div>
            </div> */}
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProductPage;

import React from "react";
import styles from "./VehicleTypes.module.css";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

interface VehicleCard {
  image: string;
  title: string;
  price: string;
}

const vehicleData: VehicleCard[] = [
  {
    image: "/landing/7.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/6.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/2.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/3.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/4.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/5.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
  {
    image: "/landing/1.png",
    title: "Box Truck",
    price: "Starting $120 / day",
  },
];

const VehicleTypes: React.FC = () => {
  return (
    <section className={styles.vehicleTypes}>
      <h2 className={styles.sectionTitle}>Vehicle Types</h2>
      <p className={styles.sectionDescription}>
        Gain access to more than 30,000 specialized commercial vehicles
        available for rent nationwide.
      </p>
      <div className={styles.cardContainer}>
        {vehicleData.map((vehicle, index) => (
          // <div key={index} className={styles.vehicleCard}>
          //   <img
          //     src={vehicle.image}
          //     alt={vehicle.title}
          //     className={styles.vehicleImage}
          //   />
          //   <h3 className={styles.vehicleTitle}>{vehicle.title}</h3>
          //   <p className={styles.vehiclePrice}>{vehicle.price}</p>
          // </div>

          <Card sx={{ maxWidth: 345, boxShadow: 0 }} className={styles.card}>
            <CardActionArea style={{ borderRadius: "16px" }}>
              <CardMedia
                className={styles.cardImage}
                component="img"
                height="140"
                image={vehicle.image}
                alt={vehicle.title}
                style={{ borderRadius: "8px" }}
              />
              <CardContent style={{ textAlign: "left" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontWeight: "600", letterSpacing: "-1px" }}
                >
                  {vehicle.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {vehicle.price}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
        <div className={styles.showAllCard}>
          <button className={styles.showAllButton}>
            Show all
            <img
              src="/images/arrow-right-icon.png"
              alt=""
              className={styles.arrowIcon}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default VehicleTypes;

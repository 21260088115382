import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import "../pages/phoneInputCustom.css";
import Select from "react-select";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RegistrationData, RootState } from "../store/types";
import { updateRegistrationData } from "../store/actions/registrationActions";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import styles2 from "./RegistrationInitialPage.module.css";

import styles from "./RegistrationInitialOwner.module.css";
import { Link } from "react-router-dom";
import SideAndNavbarOwner from "../components/Registration_Generics/SideAndNavbarOwner";
import Navbar2 from "./Add_Truck/Navbar2";
import SideAndNavbarDriver from "../components/Registration_Generics/SideAndNavbarDriver";
import NavbarDriver from "../components/Registration_Generics/NavbarDriver";
import RegisterationSideImage1 from "../components/Registration/RegisterationSideImage1";

const countryOptions = [
  { value: "+1", label: "United States (US)" },
  { value: "+373", label: "Moldova (MD)" },
];

const mapStateToPropsOwnerFirst = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToPropsOwnerFirst = {
  updateRegistrationData,
};

const connectorOwnerFirst = connect(
  mapStateToPropsOwnerFirst,
  mapDispatchToPropsOwnerFirst
);

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: "44px",
  }),
};

export type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};
const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

type PropsFromReduxOwnerFirst = ConnectedProps<typeof connectorOwnerFirst>;

const RegistrationOwnerFirst: React.FC<
  VechicleToRentProps & PropsFromReduxOwnerFirst
> = ({
  onNextButtonClick,
  onBackButtonClick,
  onNextButtonKeyDown,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
  const registrationDataCheck = useSelector(
    (state: RootState) => state.registrationData
  );
  const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);
  const [firstName, setFirstName] = useState<string>(registrationData?.firstName || "");
  const [lastName, setLastName] = useState<string>(registrationData.lastName || "");
  const [phone, setPhone] = useState<string>(
    selectedCountry.value + registrationData.phone
  );

  const currentUrl = window.location.href;

  useEffect(() => {
    setPhone(selectedCountry.value + registrationData.phone);
  }, [selectedCountry.value]);
  const [password, setPassword] = useState<string>(registrationData.password);

  useEffect(() => {
    const handleBeforeUnload = (event :any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleCountryChange = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCountry({
      value: event.target.value,
      label: event.target.value,
    });
    setPhone(event.target.value);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    setFirstName(newFirstName);
    updateRegistrationData({
      ...registrationData,
      firstName: newFirstName,
    });
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    setLastName(newLastName);
    updateRegistrationData({
      ...registrationData,
      lastName: newLastName,
    });
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    updateRegistrationData({
      ...registrationData,
      password: newPassword,
    });
  };

  const handlePhoneNumber = (phone: string) => {
    const newPhoneNumber = phone;
    setPhone(newPhoneNumber);
    updateRegistrationData({
      ...registrationData,
      phone: newPhoneNumber,
    });
  };

  const handleNextClick = () => {
    updateRegistrationData({
      ...registrationData,
      firstName: firstName,
      lastName: lastName,
      password: password,
      phone: phone,
      role: `${
        currentUrl.includes("registration_driver") ? "driver" : "renter"
      }`,
    });
    onNextButtonClick();
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <>
      <SideAndNavbarDriver />
      {/* <NavbarDriver />
       */}
      <RegisterationSideImage1 styles={styles2} />
      <div className={styles.instanceParent}>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>How will you use Truck4?</div>
          <div className={styles.inputText3}>
            Please select an option below.
          </div>
        </div>
        <div className={styles.radioGroup}>
          <div
            className={`${styles.radioGroupItem2} hoverButtons`}
            style={{
              borderLeft: "1px solid #fb814c",
              borderRadius: "8px 0 0 8px",
            }}
          >
            <Link to="/registration_driver">
              <div className={styles.reefer} style={{ color: "#fa6f32" }}>
                I want to rent
              </div>
            </Link>
          </div>

          <div className={styles.devider1} />
          <div
            className={`${styles.radioGroupItem} hoverButtons`}
            style={{
              borderLeft: "unset",
              borderRadius: "0px 0 0 0px",
            }}
          >
            <Link to="/registration_owner">
              <div className={styles.reefer}>I'm a vehicle owner</div>
            </Link>
          </div>
          <div className={styles.devider2} />
          <div
            className={`${styles.radioGroupItem3} employee-disabled hoverButtons`}
          >
            <div className={styles.reefer}>I'm an Employee</div>
          </div>
        </div>

        <div className={styles.instanceGroup}>
          <div className={styles.backButtonParent}>
            <div
              className={styles.textButton}
              onClick={handleBackButtonClick}
              style={{ cursor: "pointer" }}
            >
              <img className={styles.icon} alt="" src="/generics/icon.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.userName1} style={{ textAlign: "left" }}>
              Sign up as a driver to
            </div>
            <div className={styles.frameParent} style={{ textAlign: "left" }}>
              <div className={styles.iconsolidcheckCircleParent}>
                <img
                  className={styles.icon}
                  alt=""
                  src="/generics/iconsolidcheckcircle.svg"
                />
                <div className={styles.inputText4}>
                  Work compliantly from 150+ countries
                </div>
              </div>
              <div className={styles.iconsolidcheckCircleParent}>
                <img
                  className={styles.icon}
                  alt=""
                  src="/generics/iconsolidcheckcircle.svg"
                />
                <div className={styles.inputText4}>
                  Automate your invoicing for every client
                </div>
              </div>
              <div className={styles.iconsolidcheckCircleParent}>
                <img
                  className={styles.icon}
                  alt=""
                  src="/generics/iconsolidcheckcircle.svg"
                />
                <div className={styles.inputText4}>
                  Avoid transfer fees with 7+ payment options
                </div>
              </div>
            </div>

            <Link to="/registration_driver/sign_up" style={{ width: "100%" }}>
              <div className={`${styles.button1} hoverButtons`}>
                <div className={styles.text} onKeyDown={() => {}} role="button">
                  Next
                </div>
              </div>
            </Link>

            <div className={styles.inputTextContainer}>
              <div className={styles.inputText7}>Length</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Small</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText7}>Lift Gate</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Rail Gate</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Tuck Under</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>No Lift Gate</div>
                </div>
              </div>
            </div>
          </div>
          <img
            className={styles.groupChild}
            alt=""
            src="/generics/group-24561.svg"
          />
        </div>
        <div className={styles.inputText9}>
          <span className={styles.inputTextTxtContainer}>
            <span>Got any questions?&nbsp;</span>
            <Link to="#">
              <span className={styles.span}>Let us know!</span>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default connectorOwnerFirst(RegistrationOwnerFirst);

import React from "react";
import styles from "./PlatformFeatures.module.css";

interface Feature {
  icon: string;
  title: string;
  description: string;
  benefits: string[];
  image: string;
}

const features: Feature[] = [
  {
    icon: "/landing/Truck1.svg",
    title: "Transfer funds world-wide",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.",
    benefits: [
      "Invite team members",
      "Invite team members",
      "Invite team members",
    ],
    image: "/landing/Adminka.png",
  },
  {
    icon: "/landing/Truck1.svg",
    title: "Transfer funds world-wide",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.",
    benefits: [
      "Invite team members",
      "Invite team members",
      "Invite team members",
    ],
    image: "/landing/Adminka.png",
  },
  {
    icon: "/landing/phone.svg",
    title: "Always in the loop",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus, cum deleniti sunt in labore nihil quod quibusdam expedita nemo.",
    benefits: [
      "Invite team members",
      "Invite team members",
      "Invite team members",
    ],
    image: "/landing/Payments.png",
  },
];

const PlatformFeatures: React.FC = () => {
  return (
    <section className={styles.platformFeatures}>
      <div className={styles.header}>
        <img
          src="/landing/DotPattern.png"
          alt=""
          className={styles.dotPattern1}
        />
        <span className={styles.eyebrow}>Features</span>
        <h2 className={styles.title}>Use our platform to the fullest</h2>
        <p className={styles.description}>
          Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
          Malesuada adipiscing sagittis vel nulla nec.
        </p>
      </div>
      {features.map((feature, index) => (
        <div key={index} className={styles.featureContainer}>
          {index !== 0 && (
            <img
              src="/landing/DotPattern.png"
              alt=""
              className={styles.dotPattern}
              style={{
                ...(index % 2 === 0 && index !== 3
                  ? { right: 0 }
                  : { left: 0 }),
                ...(index === 2 ? { left: "40%" } : {}),
              }}
            />
          )}
          <div className={styles.featureContent}>
            <div className={styles.iconContainer}>
              <img src={feature.icon} alt="" className={styles.icon} />
            </div>
            <h3 className={styles.featureTitle}>{feature.title}</h3>
            <p className={styles.featureDescription}>{feature.description}</p>
            <ul className={styles.benefitsList}>
              {feature.benefits.map((benefit, benefitIndex) => (
                <li key={benefitIndex} className={styles.benefitItem}>
                  <img
                    src="/landing/Tick.svg"
                    alt=""
                    className={styles.checkIcon}
                  />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </div>

          <img
            style={{
              position: index === 2 ? "relative" : "absolute",

              ...(index % 2 === 0 && index !== 3 ? { right: 0 } : { left: 0 }),
              boxShadow: index !== 2 ? "0px 20px 25px -5px #0000001a" : "none",
            }}
            src={feature.image}
            alt={`${feature.title} illustration`}
            className={styles.featureImage}
          />
        </div>
      ))}
    </section>
  );
};

export default PlatformFeatures;

import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "../../pages/RegistrationInitialPage.module.css";
import "../../css/Registration_Generics/SideAndNavbarDriver.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

type Property1AlreadyHaveAccounType = {
  property1AlreadyHaveAccouPosition?: CSSProperties["position"];
  property1AlreadyHaveAccouTop?: CSSProperties["top"];
  property1AlreadyHaveAccouLeft?: CSSProperties["left"];

  onTheLogoMakerBuiltFor1000Click?: () => void;
};

const SideAndNavbarDriver: FunctionComponent<
  Property1AlreadyHaveAccounType
> = ({
  property1AlreadyHaveAccouPosition,
  property1AlreadyHaveAccouTop,
  property1AlreadyHaveAccouLeft,
  onTheLogoMakerBuiltFor1000Click,
}) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const property1AlreadyHaveAccounStyle: CSSProperties = useMemo(() => {
    return {
      position: property1AlreadyHaveAccouPosition,
      top: property1AlreadyHaveAccouTop,
      left: property1AlreadyHaveAccouLeft,
    };
  }, [
    property1AlreadyHaveAccouPosition,
    property1AlreadyHaveAccouTop,
    property1AlreadyHaveAccouLeft,
  ]);

  return (
    <>
      <div className={styles.RegistrationInitialPage}>
      <Link to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'}>
        <div className={styles.alreadyHaveAccount}>
          <img
            className={styles.theLogoMakerBuiltFor1000}
            alt=""
            src="/logos/Truck4 logo-1.png"
            style={{ left: "10vh" }}
            onKeyDown={() => {}}
            role="button"
            onClick={() => {
              window.location.href = "/#/main";
            }}
          />
        </div>
        </Link>
        <div className={styles.inputTextGroup}>
          <div className={styles.inputText2}>Already have an account?</div>
          <div className={`${styles.button} hoverButtons`}>
            <div className={styles.text}>
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </div>
        <div className={styles.RegistrationInitialPageChild} />
      </div>
    </>
  );
};

export default SideAndNavbarDriver;

import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/AddTruckTypeAndDetails.module.css";
import styles2 from "../../css/Registration_Driver/AddOrganization.module.css";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { RootStateTruckAdd, TruckRegistrationData } from "@/src/store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { PropsFromReduxAddTruckToRent } from "./AddTruckApplyToRent";
import {
  states,
  truckEngines,
  subtypeValues as options,
  subtypeTrucks,
  subtypeTrailers,
  subtypeBoxTrucks,
  truckManufacturers,
  trailerManufacturers,
} from "./selectValues";
import { errorToast } from "../../components/Toast/ToastMessage";

export type PropsFromReduxAddTruckTypeAndDetails = ConnectedProps<
  typeof connectorAddTruckTypeAndDetails
>;

export type AddTruckTypeAndDetails = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  setPlateState: (value: string) => void;
  setPlateNumber: (value: string) => void;
  setModel: (value: string) => void;
  setEngine: (value: string) => void;
  setYear: (value: number) => void;
  setPlateStateValue: (value: string) => void;
  setYearValue: (value: any) => void;
  setMileage: (value: string) => void;
  setSelectedTruckSubtype: (value: any) => void;
  setSelectedTruckSubtype2: (value: string) => void;
  setManufacturerValue: (value: string) => void;
  setEngineValue: (value: string) => void;
  setManufaturer: (value: string) => void;
  manufacturerValue: string;
  engineValue: string;
  manufacturer: string;
  plateState: string;
  plateNumber: string;
  model: string;
  engine: string;
  year: number;
  yearValue: string | number;
  plateStateValue: string;
  mileage: any;
  selectedTruckSubtype: string | null;
  selectedTruckSubtype2: string;
  idToUpdate?: TruckRegistrationData;
} & PropsFromReduxAddTruckToRent;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckTypeAndDetails = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddTruckTypeAndDetails: FunctionComponent<AddTruckTypeAndDetails> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  setPlateState,
  setPlateNumber,
  setModel,
  setEngine,
  setYear,
  setPlateStateValue,
  setYearValue,
  setMileage,
  setSelectedTruckSubtype,
  setSelectedTruckSubtype2,
  setManufacturerValue,
  setEngineValue,
  setManufaturer,
  manufacturerValue,
  engineValue,
  manufacturer,
  plateState,
  plateNumber,
  model,
  engine,
  year,
  yearValue,
  plateStateValue,
  mileage,
  selectedTruckSubtype,
  selectedTruckSubtype2,
  idToUpdate,
}) => {
  const handleNextClick = () => {
    if (validateForm()) {
      console.log("yes");
      onNextButtonClick();
    } else {
      errorToast("Please fill all fields");
      console.log("????");
    }
  };
  const navigate = useNavigate();

  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [isDropdownOpenManufacturer, setIsDropdownOpenManufacturer] =
    useState<boolean>(false);
  const [isDropdownOpenEngine, setIsDropdownOpenEngine] =
    useState<boolean>(false);
  const [isDropdownOpenYear, setIsDropdownOpenYear] = useState<boolean>(false);
  const [isDropdownOpenPlateState, setIsDropdownOpenPlateState] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [subtypeValue, setSubtypeValue] = useState<string>("");
  const [requiredField, setRequiredField] = useState<string>("");
  const reduxState = useSelector((state: any) => state);

  useEffect(() => {
    console.log(selectedTruckSubtype);
  }, [selectedTruckSubtype]);

  useEffect(() => {
    console.log(reduxState);
  }, []);

  const validateForm = () => {
    if (
      (!manufacturer ||
        !plateState ||
        !plateNumber ||
        !engine ||
        !yearValue ||
        !mileage ||
        !selectedTruckSubtype ||
        !selectedTruckSubtype2) &&
      !idToUpdate
    ) {
      const emptyField = !selectedTruckSubtype
        ? "selectedTruckSubtype"
        : !selectedTruckSubtype2
        ? "selectedTruckSubtype2"
        : !manufacturer
        ? "manufacturer"
        : !engine
        ? "engine"
        : !yearValue
        ? "year"
        : !plateState
        ? "plateState"
        : !plateNumber
        ? "plateNumber"
        : !mileage
        ? "mileage"
        : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  const truckAddRegistrationDataRedux = useSelector(
    (state: RootStateTruckAdd) => state
  );

  const years = Array.from({ length: 50 }, (_, index) => 2026 - index);

  const toggleDropdown = () => {
    closeDropDowns();
    setIsDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdownManufacturer = () => {
    closeDropDowns();
    setIsDropdownOpenManufacturer(!isDropdownOpenManufacturer);
  };

  const toggleDropdownEngine = () => {
    closeDropDowns();
    setIsDropdownOpenEngine(!isDropdownOpenEngine);
  };

  const toggleDropdownYear = () => {
    closeDropDowns();
    setIsDropdownOpenYear(!isDropdownOpenYear);
  };

  const toggleDropdownPlateState = () => {
    closeDropDowns();
    setIsDropdownOpenPlateState(!isDropdownOpenPlateState);
  };

  const closeDropDowns = () => {
    setIsDropdownOpenPlateState(false);
    setIsDropdownOpenYear(false);
    setIsDropdownOpenEngine(false);
    setIsDropdownOpenManufacturer(false);
    setIsDropdownOpen1(false);
  };

  const handleModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newModel = e.target.value;
    setModel(newModel);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      model: newModel,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setMileage("");
      return;
    }
    const newMileage = e.target.value;
    setMileage(newMileage);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      mileage: parseFloat(newMileage),
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPlateNumber = e.target.value;
    setPlateNumber(newPlateNumber);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateNumber: newPlateNumber,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleManufacturer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setManufacturerValue(value);
    setIsDropdownOpenManufacturer(true);
    const newManufacturer = e.target.value;
    setManufaturer(newManufacturer);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      manufacturer: newManufacturer,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setYearValue(value);
    setIsDropdownOpenYear(true);
    const newYear = parseInt(e.target.value, 10);
    setYear(newYear);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      year: newYear,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEngine = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEngineValue(value);
    setIsDropdownOpenEngine(true);
    const newEngine = e.target.value;
    setEngine(newEngine);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      engine: newEngine,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPlateState(value);
    setIsDropdownOpenPlateState(true);
    const newPlateState = e.target.value;
    setPlateState(newPlateState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateState: newPlateState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleSelectSubtype = (subtype: string) => {
    setSelectedTruckSubtype2("");
    setSelectedTruckSubtype((prevSubtype: any) => {
      if (prevSubtype === subtype) {
        const updatedRegistrationData: Partial<TruckRegistrationData> = {
          ...truckAddRegistrationData,
          vehicleType: "",
          vehicleSubtype: ""
        };
        updateTruckRegistrationData(updatedRegistrationData);
        return null;
      }

      const updatedRegistrationData: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        vehicleType: subtype,
        vehicleSubtype: ""
      };
      updateTruckRegistrationData(updatedRegistrationData);
      return subtype;
    });
  };

  const handleSubtypeVechicleSelect = (option: any) => {
    setIsDropdownOpen1(!isDropdownOpen1);
    const newSubtype2 = option;
    setSelectedTruckSubtype2(newSubtype2);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      vehicleSubtype: newSubtype2,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleManufacturerSelect = (option: any) => {
    setSelectedOption(option);
    setIsDropdownOpenManufacturer(!isDropdownOpenManufacturer);
    const newManufacturer = option;
    setManufaturer(newManufacturer);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      manufacturer: newManufacturer,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEngineSelect = (option: any) => {
    setEngine(option);
    setIsDropdownOpenEngine(!isDropdownOpenEngine);

    const newEngine = option;
    setEngine(newEngine);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      engine: newEngine,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateStateSelected = (option: any) => {
    setPlateState(option);
    setIsDropdownOpenPlateState(!isDropdownOpenPlateState);
    const newPlateState = option;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateState: newPlateState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleYearSelect = (option: any) => {
    setYearValue(option);
    setIsDropdownOpenYear(!isDropdownOpenYear);
    const newYear = option;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      year: newYear,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(subtypeValue.toLowerCase())
  );

  let optionsToDisplay;

  switch (selectedTruckSubtype) {
    case "truck":
      optionsToDisplay = subtypeTrucks; // Array for trucks
      break;
    case "trailer":
      optionsToDisplay = subtypeTrailers; // Array for trailers
      break;
    case "boxtruck":
      optionsToDisplay = subtypeBoxTrucks; // Array for box trucks
      break;
    default:
      optionsToDisplay = filteredOptions; // Default case if none match
      break;
  }

  const vehicleType = reduxState.truckAddRegistrationData.vehicleType;
  const manufacturers =
    vehicleType === "trailer" ? trailerManufacturers : truckManufacturers;

  const filteredTruckOptions = manufacturers.filter((option) =>
    option.toLowerCase().includes(manufacturerValue.toLowerCase())
  );

  const filteredEngineOptions = truckEngines.filter((option) =>
    option.toLowerCase().includes(engineValue.toLowerCase())
  );

  const filteredYearOptions = years.filter((option) =>
    option.toString().includes(yearValue.toString())
  );

  const filteredPlateState = states.filter((option) =>
    option.toLowerCase().includes(plateStateValue.toLowerCase())
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // setSubtypeValue(value);
    setIsDropdownOpen1(true);
  };

  const handleSubtypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSubtypeValue(value);
    setIsDropdownOpen1(true);
  };

  return (
    <div className={styles.registerATruckOrEdit2P}>
      <div className={styles.registerATruckOrEdit2PChild} />
      <section className={styles.frameParent}>
        <div className={styles.component35Wrapper}>
          <div className={styles.component35}>
            <div className={styles.component35Inner}>
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    loading="eager"
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.inputPrimary}>Back</div>
                </div>
                <div className={styles.inputText}>2 of 6</div>
              </div>
            </div>
            <div className={styles.userNameParent}>
              <h2 className={styles.userName}>Type & Details</h2>
              <div className={styles.inputText1}>
                Sign up with your work Google account or use the form.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputLabelWrapper}>
          <div className={styles.basicInput}>
            <div className={styles.textButton1}>
              <img className={styles.icon1} alt="" src="/generics/icon-1.svg" />
              <div className={styles.text2}>Back&nbsp;</div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div
                  className={styles.inputText2}
                  style={{
                    color: requiredField == "selectedTruckSubtype" ? "red" : "",
                  }}
                >
                  Vehicle Type*
                </div>
                <div className={styles.basicInput1}>
                  <div className={styles.inputText3}>First name&nbsp;</div>
                </div>
              </div>
              <div className={styles.tagParent}>
                {/* <div
                  className={`${styles.tag} hoverButtons ${
                    selectedTruckSubtype?.includes("reefer")
                      ? styles.selectedTag
                      : ""
                  }`}
                  onClick={() => handleSelectSubtype("reefer")}
                >
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    loading="eager"
                    alt=""
                    src="/truck_types/Reefer_Refrigerated_Trucks.svg"
                  />
                  <div className={styles.reefer}>Reefer</div>
                </div> */}
                <div
                  className={`${styles.tag} hoverButtons  ${
                    selectedTruckSubtype == "truck" ? styles.selectedTag : ""
                  }`}
                  onClick={() => handleSelectSubtype("truck")}
                >
                  <img
                    className={styles.earnRevenueFromYourIdleFl1}
                    loading="eager"
                    alt=""
                    src="/truck_types/17.svg"
                  />
                  <div className={styles.reefer1}>Truck</div>
                </div>
                <div
                  className={`${styles.tag} hoverButtons ${
                    selectedTruckSubtype?.includes("trailer")
                      ? styles.selectedTag
                      : ""
                  }`}
                  onClick={() => handleSelectSubtype("trailer")}
                >
                  <img
                    className={styles.earnRevenueFromYourIdleFl2}
                    loading="eager"
                    alt=""
                    src="/truck_types/Trailer.svg"
                  />
                  <div className={styles.reefer2}>Trailer</div>
                </div>
                {/* <div
                  className={`${styles.tag} hoverButtons ${
                    selectedTruckSubtype?.includes("tractor")
                      ? styles.selectedTag
                      : ""
                  }`}
                  onClick={() => handleSelectSubtype("tractor")}
                >
                  <img
                    className={styles.earnRevenueFromYourIdleFl3}
                    loading="eager"
                    alt=""
                    src="/truck_types/Beverage_Trucks.png"
                  />
                  <div className={styles.reefer3}>Tractor</div>
                </div> */}
                <div
                  className={`${styles.tag} hoverButtons ${
                    selectedTruckSubtype?.includes("boxtruck")
                      ? styles.selectedTag
                      : ""
                  }`}
                  onClick={() => handleSelectSubtype("boxtruck")}
                >
                  <img
                    className={styles.earnRevenueFromYourIdleFl4}
                    loading="eager"
                    alt=""
                    src="/truck_types/Box_Trucks.svg"
                  />
                  <div className={styles.reefer4}>Box Truck</div>
                </div>
                {/* <div className={`${styles.tag5} hoverButtons`}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl5}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer5}>tracktor</div>
                </div> */}
              </div>
            </div>
            <div className={styles.inputWithLabelWrapper}>
              <div className={styles.inputWithLabel1}>
                <div className={styles.inputText4}>Vehicle Subtype*</div>
                <div
                  className={styles.basicInput2}
                  style={{
                    justifyContent: "space-between",
                    border:
                      requiredField == "selectedTruckSubtype2"
                        ? "1px solid red"
                        : "",
                  }}
                >
                  <input
                    className={styles.inputText7}
                    placeholder="Subtype"
                    type="text"
                    value={selectedTruckSubtype2 || ""}
                    onChange={handleSubtypeChange}
                    onClick={toggleDropdown}
                  />

                  <StyledButton
                    className={styles2.dropdownButton}
                    onKeyDown={() => {}}
                    role="button"
                    onClick={toggleDropdown}
                  >
                    <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                  </StyledButton>
                  {isDropdownOpen1 && (
                    <Container>
                      {/* filteredOptionsNoTrailers */}
                      {optionsToDisplay.map((option, index) => (
                        <Item
                          key={index}
                          className={`hoverDropdown ${
                            selectedTruckSubtype2 == option ? "text-theme" : ""
                          } `}
                          onClick={() => handleSubtypeVechicleSelect(option)}
                        >
                          {option}
                        </Item>
                      ))}
                    </Container>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.basicInputInner}>
              <div className={styles.frameChild} />
            </div>
            <div className={styles.inputWithLabelGroup}>
              <div className={styles.inputWithLabelContainer}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.inputText8}>Manufacturer*</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownManufacturer}
                    style={{
                      border:
                        requiredField == "manufacturer" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Manufacturer"
                      type="text"
                      value={manufacturer || idToUpdate?.manufacturer}
                      onChange={handleManufacturer}
                    />
                    <StyledButton
                      className={styles2.dropdownButton}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdownManufacturer}
                    >
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpenManufacturer && (
                      <Container>
                        {filteredTruckOptions.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${
                              manufacturer == option ? "text-theme" : ""
                            } `}
                            onClick={() => handleManufacturerSelect(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
                <div className={styles.inputWithLabel4}>
                  <div className={styles.inputText10}>Model</div>
                  <div
                    className={styles.basicInput3}
                    // style={{
                    //   border: requiredField == "model" ? "1px solid red" : "",
                    // }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Model"
                      type="text"
                      value={model || idToUpdate?.model}
                      onChange={handleModel}
                      maxLength={25}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputWithLabel5}>
                  <div className={styles.inputText12}>Engine&nbsp;</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownEngine}
                    style={{
                      border: requiredField == "engine" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Engine"
                      type="text"
                      value={engine || idToUpdate?.engine}
                      onChange={handleEngine}
                    />
                    <StyledButton
                      className={styles2.dropdownButton}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdownEngine}
                    >
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpenEngine && (
                      <Container>
                        {filteredEngineOptions.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${
                              engine == option ? "text-theme" : ""
                            } `}
                            onClick={() => handleEngineSelect(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
                <div className={styles.inputWithLabel6}>
                  <div className={styles.inputText14}>Year</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownYear}
                    style={{
                      border: requiredField == "year" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Year"
                      type="text"
                      value={yearValue}
                      onChange={handleYear}
                    />
                    <StyledButton
                      className={styles2.dropdownButton}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdownYear}
                    >
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpenYear && (
                      <Container>
                        {filteredYearOptions.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${
                              year == option ? "text-theme" : ""
                            } `}
                            onClick={() => handleYearSelect(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.basicInputChild}>
              <div className={styles.frameItem} />
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.inputWithLabelParent1}>
                <div className={styles.inputWithLabel7}>
                  <div className={styles.inputText16}>Plate State</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownPlateState}
                    style={{
                      border:
                        requiredField == "plateState" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Plate State"
                      type="text"
                      value={plateState || idToUpdate?.plateState}
                      onChange={handlePlateState}
                    />
                    <StyledButton
                      className={styles2.dropdownButton}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdownPlateState}
                    >
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpenPlateState && (
                      <Container>
                        {filteredPlateState.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${
                              plateState == option ? "text-theme" : ""
                            } `}
                            onClick={() => handlePlateStateSelected(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
                <div className={styles.inputWithLabel8}>
                  <div className={styles.inputText10}>Mileage*</div>
                  <div
                    className={styles.basicInput3}
                    style={{
                      border: requiredField == "mileage" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      style={{ textAlign: "end" }}
                      placeholder="Mileage"
                      type="text"
                      value={
                        mileage ||
                        (idToUpdate?.mileage && isNaN(Number(mileage))
                          ? idToUpdate?.mileage
                          : "")
                      }
                      onChange={handleMileage}
                      maxLength={20}
                    />
                    <div style={{ paddingLeft: "5px" }}>
                      <b>Mi</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.inputWithLabel9}>
                <div className={styles.inputText21}>Plate Number</div>
                <div
                  className={styles.basicInput4}
                  style={{
                    border:
                      requiredField == "plateNumber" ? "1px solid red" : "",
                  }}
                >
                  <input
                    className={styles.inputText22}
                    placeholder="Plate Number"
                    type="text"
                    value={plateNumber || idToUpdate?.plateNumber}
                    onChange={handlePlateNumber}
                    maxLength={25}
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputTextParent}>
              <div className={styles.inputText23}>Length</div>
              <div className={styles.tagGroup}>
                <div className={styles.tag6}>
                  <input
                    className={styles.earnRevenueFromYourIdleFl6}
                    type="checkbox"
                  />
                  <div className={styles.reefer6}>Small</div>
                </div>
                <div className={styles.tag7}>
                  <input
                    className={styles.earnRevenueFromYourIdleFl7}
                    type="checkbox"
                  />
                  <div className={styles.reefer7}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextGroup}>
              <div className={styles.inputText24}>Lift Gate</div>
              <div className={styles.tagContainer}>
                <div className={styles.tag8}>
                  <input
                    className={styles.earnRevenueFromYourIdleFl8}
                    type="checkbox"
                  />
                  <div className={styles.reefer8}>Rail Gate</div>
                </div>
                <div className={styles.tag9}>
                  <input
                    className={styles.earnRevenueFromYourIdleFl9}
                    type="checkbox"
                  />
                  <div className={styles.reefer9}>Tuck Under</div>
                </div>
                <div className={styles.tag10}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl10}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer10}>No Lift Gate</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.component35Wrapper}>
          <div className={styles.buttonContainer}>
            <button className={styles.button6}>
              <div className={styles.text3}>Save as a draft</div>
            </button>
            <div className={styles.buttonParent1}>
              <button className={styles.button7} onClick={onBackButtonClick}>
                <div className={styles.inputDropdownFrame}>Back</div>
              </button>
              <button className={styles.button8} onClick={handleNextClick}>
                <div className={styles.text4}>Next</div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connectorAddTruckTypeAndDetails(AddTruckTypeAndDetails);

const StyledButton = styled.button`
  border: 0;
  right: 5%;
  background-color: transparent;
`;

const Container = styled.div`
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

import React, { useState } from "react";
import Header from "./Header";

// import VehicleTypes from './VehicleTypes';
// import Features from './Features';
// import ProcessSection from './ProcessSection';
// import OwnerSection from './OwnerSection';
// import PlatformFeatures from './PlatformFeatures';
// import FAQSection from './FAQSection';
// import BlogSection from './BlogSection';
// import NewsletterSection from './NewsletterSection';
// import Footer from './Footer';
import styles from "./HomePage.module.css";
import HeroSection from "./HeroSection";
import PartnersSection from "./PartnersSection";
import VehicleTypes from "./VehicleTypes";
import MapPageNavbar from "../Map_Page/MapPageNavbar";
import Features from "./Features";
import Footer from "../Map_Page/Footer";
import ProcessSection from "./ProcessSection";
import OwnerSection from "./OwnerSection";
import PlatformFeatures from "./PlatformFeatures";
// import "react-joyride/lib/react-joyride-compiled.css";

const HomePage: React.FC = () => {
  return (
    <div className={styles.homePage}>
      <MapPageNavbar />
      <main>
        <HeroSection />
        <PartnersSection />
        <VehicleTypes />
        <Features />
        <ProcessSection />
        <OwnerSection />
        <PlatformFeatures />
        {/* <FAQSection />
        <BlogSection />
        <NewsletterSection /> */}
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;

import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import styles from "./MapPage.module.css";

const TrucksList = (props: any) => {
  console.log(props);
  console.log("props");
  return (
    <div className={styles.mainTruckList}>
      {props.trucks.length == 0 && (
        <small className="text-theme">No trucks found</small>
      )}
      {props.trucks.map((truck: any, index: number) => {
        const truckIndex = props.startIdx + index;
        return (
          <div
            className={`${styles.truckContainer2} singleTruck`}
            key={truck._id}
          >
            <Carousel
              className={props.styles.vecicleCard2}
              id="truck_container"
              interval={null}
            >
              {Object.entries(truck.images)
                .filter(([key, value]) => value)
                .map(([key, value]) => (
                  <Carousel.Item key={`${truck._id}-${key}`}>
                    {/* {truck.status === "inrent" && (
                      <div
                        className={props.styles.overlay}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          color: "#fa6f32",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "20px",
                          fontWeight: "bold",
                          zIndex: 1,
                          pointerEvents: "none",
                        }}
                      >
                        In rent
                      </div>
                    )} */}
                    <div
                      className={`${
                        props.styles.frameParent3 || "frame-24031@3x.png"
                      } hoverButtons`}
                      style={{ cursor: "pointer" }}
                    >
                      <Link to={`/truck/${truck._id}`} key={index}>
                        <img
                          src={`${
                            props.trucks[index].images[key] ||
                            "frame-24031@3x.png"
                          }`}
                          className={`${props.styles.frameParent3} hoverButtons`}
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </Link>
                      <div
                        style={{ zIndex: "99", cursor: "pointer" }}
                        onClick={() => props.handleHeartClick(index)}
                        className={`${props.styles.mdicardsHeartOutlineIcon}  ${
                          props.selectedHearts.includes(index)
                            ? props.styles.selectedHeart
                            : ""
                        }`}
                      >
                        <img
                          onKeyDown={() => {}}
                          role="button"
                          alt=""
                          style={{
                            zIndex: "99",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          src={
                            props.selectedHearts.includes(index)
                              ? "generics/redHeartIcon.svg"
                              : "generics/mdicardsheartoutline.svg"
                          }
                        />
                      </div>
                      <div className={props.styles.badgesOnCardParent}>
                        <div className={props.styles.badgesOnCard2}>
                          <img
                            className={props.styles.iconoutlinesearch}
                            alt=""
                            src="/generics/iconoutlineroybam2.svg"
                          />
                        </div>
                        <div className={props.styles.badgesOnCard3}>
                          <img
                            className={props.styles.iconoutlinesearch}
                            alt=""
                            src="/generics/rivipcrownline1.svg"
                          />
                        </div>
                        <div className={props.styles.badgesOnCard4}>
                          <img
                            className={props.styles.iconoutlinesearch}
                            alt=""
                            src="/generics/iconoutlinechevrondoubleup1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>

            <div className={props.styles.component31}>
              <div
                className={props.styles.peterbilt579Parent}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5%",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className={props.styles.text}
                    style={{ textTransform: "capitalize" }}
                  >
                    {truck.manufacturer}
                  </div>
                  <div className={props.styles.vecicleCardrectangle1271}>
                    <div
                      className={props.styles.vecicleCardrectangle1271Child}
                    />
                  </div>
                  <div
                    className={props.styles.tandemTractor}
                    style={{ textTransform: "capitalize" }}
                  >
                    {truck.vehicleType}
                  </div>
                </div>
                <div
                  className={props.styles.component31Inner}
                  style={{ paddingRight: "5%  " }}
                >
                  <div className={props.styles.dayWrapper}>
                    <div className={props.styles.milesAway}>
                      <span className={props.styles.span}>
                        ${truck.rentPerDay}
                      </span>
                      <b>&nbsp;/&nbsp;</b>
                      <span className={props.styles.span}>Day</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={props.styles.toledoOhio43612Parent}>
                <div className={props.styles.milesAway}>
                  {props.trucks[index].city?.toUpperCase()}
                </div>
                <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div>
                <div className={props.styles.frameWrapper}>
                  <div className={props.styles.materialSymbolsstarParent}>
                    <img
                      className={props.styles.materialSymbolsstarIcon}
                      alt=""
                      src="/generics/materialsymbolsstar.svg"
                    />
                    <div className={props.styles.milesAway}>5.0 (11)</div>
                  </div>
                </div>
                <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div>
                <div className={props.styles.iconoutlinetruckParent}>
                  <img
                    className={props.styles.iconoutlinetruck}
                    alt=""
                    src="/generics/iconoutlinetruck.svg"
                  />
                  <div className={props.styles.k}>{`${
                    truck.mileage / 1000
                  }k `}</div>
                  <div className={props.styles.mileage}>Mileage</div>
                </div>
                <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div>

                <div className={props.styles.materialSymbolsstarParent}>
                  <img
                    className={props.styles.pointMapIcon}
                    alt=""
                    src="/generics/point-map.svg"
                  />
                  {/* <div className={props.styles.milesAway}>52 miles away</div> */}
                </div>
              </div>
              {truck.status === "inrent" && (
                <p style={{ color: "red" }}>
                  <b>In rent</b>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TrucksList;

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import AddTruckFirstPage from "./AddTruckFirstPage";
import { RootStateTruckAdd, TruckRegistrationData } from "../../store/types";
import { ConnectedProps, connect } from "react-redux";
import AddTruckApplyToRent from "./AddTruckApplyToRent";
import Navbar2 from "./Navbar2";
import AddTruckTypeAndDetails from "./AddTruckTypeAndDetails";
import AddTruckSpecs from "./AddTruckSpecs";
import AddTruckExpirationDate from "./AddTruckExpirationDate";
import AddtruckExpirationDate2 from "./AddTruckExpirationDate2";
import AddTruckPrice from "./AddTruckPrice";
import AddTruckFinal from "./AddTruckFinal";
import { useDispatch, useSelector } from "react-redux";

import { updateTruckRegistrationData as handleUpdateTruckRegistrationData } from "../../store/actions/registrationActions";
import AddTruckNotOwner from "./AddTruckNotOwner";

type OwnProps = PropsFromRedux & {
  truckRegistrationDataToUpdate?: TruckRegistrationData;
  handleUpdateTruckRegistrationData?: (data: TruckRegistrationData) => void;
  showSidebar?: boolean;
  autoClick?: boolean;
  idToUpdate?: TruckRegistrationData;
};

interface RegistrationInitialPageProps {
  TruckRegistrationData?: TruckRegistrationData;
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
}

const mapStateToProps = (state: RootStateTruckAdd) => ({
  TruckRegistrationData: state.truckRegistrationData,
});

const mapDispatchToProps = {
  handleUpdateTruckRegistrationData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AddTruckInitial: FunctionComponent<OwnProps> = ({
  handleUpdateTruckRegistrationData,
  TruckRegistrationData,
  truckRegistrationDataToUpdate,
  showSidebar = true,
  autoClick = false,
  idToUpdate,
}) => {
  // states for AddTruckApplyToRent
  const [inputValue, setInputValue] = useState<string>("");
  const [VINNumber, setVINNumber] = useState<string>("");
  const [plateState, setPlateState] = useState<string>("");
  const [plateNumber, setPlateNumber] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  // states for AddTruckTypeAndDetails
  const [model, setModel] = useState<string>("");
  const [engine, setEngine] = useState<string>("");
  const [year, setYear] = useState<number>(0);
  const [yearValue, setYearValue] = useState<number>(idToUpdate?.year || 0);
  const [plateStateValue, setPlateStateValue] = useState<string>("");
  const [mileage, setMileage] = useState<number | string>(
    idToUpdate?.mileage || ""
  );
  const [selectedTruckSubtype, setSelectedTruckSubtype] = 
    useState<string>(idToUpdate?.vehicleType || "");
  const [selectedTruckSubtype2, setSelectedTruckSubtype2] =
    useState<string>(idToUpdate?.vehicleSubtype || "");
  const [manufacturerValue, setManufacturerValue] = useState<string>("");
  const [engineValue, setEngineValue] = useState<string>("");
  const [manufacturer, setManufaturer] = useState<string>("");
  // states for AddTruckSpecs
  const [doorType, setDoorType] = useState<string>(idToUpdate?.doorType || "");
  const [liftGateType, setLiftGateType] = useState<string>(
    idToUpdate?.liftGateType || ""
  );
  const [dockHeight, setDockHeight] = useState<string>(
    idToUpdate?.dockHeight || ""
  );
  const [allowTravelOutOfState, setAllowTravelOutOfState] = useState<boolean>(
    idToUpdate?.allowTravelOutOfState || false
  );
  const [gps, setGps] = useState<boolean>(idToUpdate?.GPS || false);
  const [chassis, setChassis] = useState<string>(idToUpdate?.chassis || "");
  const [transmissionType, setTransmissionType] = useState<string>(
    idToUpdate?.transmissionType || "mannual"
  );
  const [lengthValue, setLengthValue] = useState<any>({});
  const [cargoValue, setCargoValue] = useState<string>("");
  const [cargoSecurementTrack, setCargoSecurementTrack] = useState<string>("");
  // states for AddTruckExpirationDate
  const [fileNames, setFileNames] = useState<Record<string, string>>({});
  const [description, setDescription] = useState<string>("");
  // states for AddTruckExpirationDate2
  const [startDate, setStartDate] = useState<Date>(
    idToUpdate?.registrationStartDate
      ? new Date(idToUpdate?.registrationStartDate)
      : new Date()
  );
  const [endDate, setEndDate] = useState<Date>(
    idToUpdate?.registrationEndDate
      ? new Date(idToUpdate?.registrationEndDate)
      : new Date()
  );
  const [insurranceStartDate, setInsurranceStartDate] = useState<Date>(
    idToUpdate?.insuranceStartDate
      ? new Date(idToUpdate?.insuranceStartDate)
      : new Date()
  );
  const [insurranceEndDate, setInsurranceEndDate] = useState<Date>(
    idToUpdate?.insuranceEndDate
      ? new Date(idToUpdate?.insuranceEndDate)
      : new Date()
  );
  const [inspectionDate, setinspectionDate] = useState<Date>(
    idToUpdate?.inspectionDate
      ? new Date(idToUpdate?.inspectionDate)
      : new Date()
  );
  // states for AddTruckPrice
  const [costRentPerDay, setCostRentPerDay] = useState<number>(
    idToUpdate?.rentPerDay || 0
  );
  const [prepaidMileage, setPrepaidMileage] = useState<number | string>(
    idToUpdate?.rentPrepaidMileage || 0
  );
  const [leaseCostRentPerDay, setLeaseCostRentPerDay] = useState<number>(
    idToUpdate?.leasePerDay || 0
  );
  const [leasePrepaidMileage, setLeasePrepaidMileage] = useState<
    number | string
  >(idToUpdate?.leasePrepaidMileage || 0);
  const [deposite, setDeposite] = useState<number>(idToUpdate?.deposit || 0);
  const [equipementPrice, setEquipementPrice] = useState<number>(
    idToUpdate?.equipmentPrice || 0
  );
  const [insurrancePrice, setInsurrancePrice] = useState<number>(
    idToUpdate?.insurancePrice || 0
  );

  // const [enableLeaseToPurchase, setEnableLeaseToPurchase] = useState<boolean>(
  //   idToUpdate?.LeaseForPurchase || false
  // );
  const [taxes, setTaxes] = useState<number>(idToUpdate?.taxes || 0);
  const [enableForLease, setEnableForLease] = useState<boolean>(
    idToUpdate?.enableForLease || false
  );
  const [enableForRent, setEnableForRent] = useState<boolean>(
    idToUpdate?.enableForRent || false
  );

  const [leaseForPurchase, setLeaseForPurchase] = useState<boolean>(
    idToUpdate?.enableForLeaseForPurchase || false
  );

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const reduxState = useSelector((state: any) => state);

  const registrationData =
    truckRegistrationDataToUpdate || TruckRegistrationData;
  const handleNextButtonClick = (step?: number) => {
    if (step !== undefined) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (autoClick) handleNextButtonClick();
  }, []);

  const handleBackButtonClick = (step?: number) => {
    if (currentStep === 0) {
      navigate("/add_truck");
    }

    if (step == 2) {
      console.log(step);
      console.log(currentStep);
      setCurrentStep(currentStep - 2);
      console.log(currentStep);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <AddTruckFirstPage onNextButtonClick={handleNextButtonClick} />
          </>
        );
      case 2:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckApplyToRent
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              setPlateNumber={setPlateNumber}
              setInputValue={setInputValue}
              setPlateState={setPlateState}
              setVINNumber={setVINNumber}
              setCity={setCity}
              setState={setState}
              setZipCode={setZipCode}
              city={city}
              state={state}
              zipCode={zipCode}
              inputValue={inputValue}
              plateNumber={plateNumber}
              truckRegData={registrationData}
              plateState={plateState}
              VINNumber={VINNumber}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
            />
          </div>
        );
      case 3:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckTypeAndDetails
              setPlateState={setPlateState}
              setPlateNumber={setPlateNumber}
              setModel={setModel}
              setEngine={setEngine}
              setYear={setYear}
              setYearValue={setYearValue}
              setPlateStateValue={setPlateStateValue}
              setMileage={setMileage}
              setSelectedTruckSubtype={setSelectedTruckSubtype}
              setSelectedTruckSubtype2={setSelectedTruckSubtype2}
              setManufacturerValue={setManufacturerValue}
              setManufaturer={setManufaturer}
              setEngineValue={setEngineValue}
              engineValue={engineValue}
              manufacturer={manufacturer}
              manufacturerValue={manufacturerValue}
              plateState={plateState}
              plateNumber={plateNumber}
              model={model}
              engine={engine}
              year={year}
              yearValue={yearValue}
              plateStateValue={plateStateValue}
              mileage={mileage}
              selectedTruckSubtype={selectedTruckSubtype}
              selectedTruckSubtype2={selectedTruckSubtype2}
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              truckRegData={registrationData}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
            />
          </div>
        );
      case 4:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckSpecs
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              truckRegData={registrationData}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
              doorType={doorType}
              setDoorType={setDoorType}
              liftGateType={liftGateType}
              setLiftGateType={setLiftGateType}
              dockHeight={dockHeight}
              setDockHeight={setDockHeight}
              allowTravelOutOfState={allowTravelOutOfState}
              setAllowTravelOutOfState={setAllowTravelOutOfState}
              selectedTruckSubtype2={selectedTruckSubtype2}
              selectedTruckSubtype={selectedTruckSubtype}
              gps={gps}
              setGps={setGps}
              chassis={chassis}
              setChassis={setChassis}
              transmissionType={transmissionType}
              setTransmissionType={setTransmissionType}
              lengthValue={lengthValue}
              setLengthValue={setLengthValue}
              cargoValue={cargoValue}
              setCargoValue={setCargoValue}
              cargoSecurementTrack={cargoSecurementTrack}
              setCargoSecurementTrack={setCargoSecurementTrack}
            />
          </div>
        );
      case 5:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckExpirationDate
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              fileNames={fileNames}
              setFileNames={setFileNames}
              setDescription={setDescription}
              description={description}
              truckRegData={registrationData}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
            />
          </div>
        );
      case 6:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddtruckExpirationDate2
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              setFileNames={setFileNames}
              fileNames={fileNames}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setInsurranceStartDate={setInsurranceStartDate}
              setInsurranceEndDate={setInsurranceEndDate}
              setinspectionDate={setinspectionDate}
              startDate={startDate}
              endDate={endDate}
              insurranceStartDate={insurranceStartDate}
              insurranceEndDate={insurranceEndDate}
              inspectionDate={inspectionDate}
              truckRegData={registrationData}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
            />
          </div>
        );
      case 7:
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckPrice
              handleUpdateTruckRegistrationData={
                handleUpdateTruckRegistrationData
              }
              setCostRentPerDay={setCostRentPerDay}
              setPrepaidMileage={setPrepaidMileage}
              setLeaseCostRentPerDay={setLeaseCostRentPerDay}
              setLeasePrepaidMileage={setLeasePrepaidMileage}
              setDeposite={setDeposite}
              setEquipementPrice={setEquipementPrice}
              setInsurrancePrice={setInsurrancePrice}
              setTaxes={setTaxes}
              setEnableForLease={setEnableForLease}
              setEnableForRent={setEnableForRent}
              setLeaseForPurchase={setLeaseForPurchase}
              costRentPerDay={costRentPerDay}
              prepaidMileage={prepaidMileage}
              leaseCostRentPerDay={leaseCostRentPerDay}
              leasePrepaidMileage={leasePrepaidMileage}
              leaseForPurchase={leaseForPurchase}
              deposite={deposite}
              equipementPrice={equipementPrice}
              insurrancePrice={insurrancePrice}
              taxes={taxes}
              enableForLease={enableForLease}
              enableForRent={enableForRent}
              truckRegData={registrationData}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              idToUpdate={idToUpdate}
            />
          </div>
        );
      case 8: {
        return (
          <div style={{ backgroundColor: "#f8fafc" }}>
            {showSidebar && <Navbar2 isNotOnMapPage={true} />}
            <AddTruckFinal />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    // <>
    //   {reduxState.currentUser.role === "driver" ? (
    //     <AddTruckNotOwner />
    //   ) : (
    //     renderCurrentStep()
    //   )}
    // </>
    renderCurrentStep()
  );
};

export default AddTruckInitial;

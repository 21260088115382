import React, { useEffect, useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganizationVerification2.module.css";
import { RootState } from "@/src/store/types";
import { useSelector } from "react-redux";
import { errorToast } from "../Toast/ToastMessage";
import { Link } from "react-router-dom";
import { getAuthHeaders } from "../../utils/GetHeaders";
import {
  REACT_APP_OTP_API_BASE_URL,
  REACT_APP_LONG_COUNTDOWN,
} from "../../utils/UrlConstants";

type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onSkipBackwards: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  role: string;
  onClick?: () => void;
};

const AddOrganizationDetails2: React.FC<VechicleToRentProps> = ({
  onNextButtonClick,
  onSkipBackwards,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [buttonStyle, setButtonStyle] = useState(styles.button3);
  const reduxStateRegistrationData = useSelector(
    (state: RootState) => state.registrationData
  );
  const [countdown, setCountdown] = useState<number>(
    parseInt(REACT_APP_LONG_COUNTDOWN || "60")
  );
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const isUserUpdated = localStorage.getItem("currentUserUpdated")
    ? true
    : false;
  var isInitialRender = true;

  useEffect(() => {
    if (isUserUpdated && isInitialRender) {
      handleSendCodeClick();
      localStorage.removeItem("currentUserUpdated");
      isInitialRender = false;
    }
    if (buttonDisabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown: number) => prevCountdown - 1);
      }, 1000);

      if (countdown === 0) {
        clearInterval(timer);
        setButtonDisabled(false);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, buttonDisabled]);

  const handleOpenMailbox = () => {
    window.location.href = `mailto:`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;

    setInputValue(formattedValue);

    if (/^\d-\d-\d-\d-\d$/.test(formattedValue)) {
      setButtonStyle(styles.button1);
    } else {
      setButtonStyle(styles.button3);
    }
  };

  const handleNextClick = async () => {
    try {
      const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/verify/email`;

      const requestBody = {
        otp: inputValue,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          ...getAuthHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        return response.json().then((data) => {
          errorToast(`Bad request: ${data.error}`);
        });
      } else {
        onNextButtonClick();
      }

      const data = await response.json();
      if (data.exists === false) {
        errorToast("Email does not exist");
      } else if (data.exists === true) {
        errorToast("Email already exists");
      } else {
        console.log("Success:", data);
      }
    } catch (error) {
      errorToast(`${error}`);
      console.error("Error:", error);
    }
  };

  const handleSendCodeClick = async () => {
    try {
      const email = reduxStateRegistrationData.email;
      const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/send/email`;

      const requestBody = {
        email: email,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        errorToast("Incorrect One Time Password");
        console.error("Login failed", response);
        return false;
      }

      const data = await response.json();
      if (data.exists === false) {
        errorToast("Email does not exist");
      } else if (data.exists === true) {
        errorToast("Email already exists");
      } else {
        setCountdown(parseInt(REACT_APP_LONG_COUNTDOWN || "60"));
        setButtonDisabled(true);
        console.log("Success:", data);
      }
    } catch (error) {
      errorToast(`${error}`);
      console.error("Error:", error);
    }
  };

  const handleSkipBackwardsClick = () => {
    onSkipBackwards();
  };

  return (
    <div className={styles.lookingForRent07}>
      <div className={styles.lookingForRent07Child} />
      <div className={styles.backButtonParent}>
        <div
          className={styles.textButton}
          onClick={handleSkipBackwardsClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon} alt="" src="/generics/icon.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.iconParent}>
              <img
                className={styles.icon}
                alt=""
                src="/generics/iconsolidcheckcircle.svg"
              />
              <div className={styles.userName}>Verification phone number</div>
            </div>
            <div className={styles.button1}>
              <div className={styles.text}>Next</div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText3}>Length</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Small</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText3}>Lift Gate</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Rail Gate</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Tuck Under</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>No Lift Gate</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.textButtonGroup}>
            <div className={styles.textButton2}>
              <img className={styles.icon} alt="" src="/generics/icon.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.icon} alt="" src="/generics/icon3.svg" />
              <div className={styles.userName}>Verification e-mail</div>
            </div>
            <div className={styles.inputField}>
              <span className={styles.inputTextTxtContainer}>
                <span>To complete the registration process, please&nbsp;</span>
                <b>check your inbox</b>
                <span>
                  &nbsp;for an email from us. Inside the email, you will find a
                  verification link.&nbsp;
                </span>
              </span>
            </div>
            <div className={styles.inputField}>
              <span className={styles.inputTextTxtContainer}>
                <b>If you don't see the email in your inbox,</b>
                <span>
                  {" "}
                  please check your spam or junk folder as it may have been
                  filtered incorrectly. To ensure you receive future
                  communications from us, please add our email address to your
                  contacts or whitelist.
                </span>
              </span>
            </div>
            <div className={styles.inputTextWrapper}>
              <div className={styles.inputText7}>
                <span className={styles.inputTextTxtContainer}>
                  <span className={styles.weHaveSentAnEMailToK1g}>
                    <span>We have sent an e-mail to&nbsp;</span>
                    <span className={styles.k1gmailcom}>
                      {reduxStateRegistrationData.email}
                    </span>
                  </span>
                  <span className={styles.copyAndPaste}>
                    Copy and paste the code from the email
                  </span>
                </span>
              </div>
            </div>
            <div className={`${styles.button2} hoverButtons`}>
              <div
                className={styles.text}
                onKeyDown={() => {}}
                role="button"
                onClick={handleOpenMailbox}
              >
                Open my mailbox
              </div>
            </div>
            <div>
              <span className={styles.copyAndPaste}>
                <b>
                  You can lave the verification process for later and continue
                </b>
              </span>
              <Link to="/admin/truck_management">
                <div className={`${styles.button3} hoverButtons`}>
                  <div
                    className={styles.text}
                    onKeyDown={() => {}}
                    role="button"
                  >
                    Skip
                  </div>
                </div>
              </Link>
            </div>
            <div className={styles.frameChild} />
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <span className={styles.reefer}>Verification code</span>
                <p>
                  Didn't received email?
                  {buttonDisabled ? (
                    <b
                      style={{
                        color: "#fa6f32",
                        background: "white",
                        border: "none",
                      }}
                    >
                      {" "}
                      Resend Code in {countdown} seconds
                    </b>
                  ) : (
                    <b
                      style={{
                        color: "#fa6f32",
                        background: "white",
                        border: "none",
                      }}
                      onClick={handleSendCodeClick}
                    >
                      {" "}
                      Resend Code
                    </b>
                  )}
                </p>
                <div className={styles.basicInput}>
                  <div className={styles.inputText9}>
                    {" "}
                    <input
                      type="text"
                      className={styles.inputText6}
                      placeholder="0-0-0-0-0"
                      style={{ border: "none" }}
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.button3} hoverButtons`}>
                <div
                  className={styles.text}
                  onKeyDown={() => {}}
                  role="button"
                  onClick={handleNextClick}
                >
                  Apply
                </div>
              </div>
            </div>
            <div className={`${styles.button4} hoverButtons`}>
              <div className={styles.text}>Next</div>
            </div>
            <div className={styles.inputTextParent1}>
              <div className={styles.inputText3}>Length</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Small</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextParent1}>
              <div className={styles.inputText3}>Lift Gate</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Rail Gate</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Tuck Under</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>No Lift Gate</div>
                </div>
              </div>
            </div>
          </div>
          <img
            className={styles.groupChild}
            alt=""
            src="/generics/group-2456.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationDetails2;

import React from "react";
import styles from "./PartnersSection.module.css";

const PartnersSection: React.FC = () => {
  return (
    <section className={styles.partnersSection}>
      <p className={styles.partnerText}>
        Used by 20+ businesses from small startups to household names
      </p>
      <img
        src="/images/partner-logos.png"
        alt="Partner company logos"
        className={styles.partnerLogos}
      />
    </section>
  );
};

export default PartnersSection;

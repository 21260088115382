import { FunctionComponent } from "react";
import styles from "./css/UserAdministration.module.css";
import AdminNavBarLeadingContent from "./AdminNavBarLeadingContent";
interface ICollapsedsidebar {
  isCollapsed?: boolean;
}

const AdminSidebar: FunctionComponent<ICollapsedsidebar> = ({
  isCollapsed,
}) => {
  return (
    <div
      className={`${styles.simpleWithIconsBadgesAnd} ${
        isCollapsed ? styles.collapsedHeader : ""
      }`}
    >
      <AdminNavBarLeadingContent isCollapsed={isCollapsed} />
    </div>
  );
};

export default AdminSidebar;

import React from "react";
import styles from "./Features.module.css";

interface FeatureItemProps {
  title: string;
  description: string;
  isActive: boolean;
}

const Features: React.FC = () => {
  const features = [
    {
      title: "Wide Range of Trucks",
      description:
        "Offering a variety of trucks for different needs, including light trucks, heavy-duty trucks, and specialized vehicles.",
      isActive: true,
    },
    {
      title: "Flexible Rental Terms",
      description:
        "Options for short-term and long-term rentals, with customizable terms to meet individual client needs.",
      isActive: false,
    },
    {
      title: "Insurance and Support",
      description:
        "Various insurance options offered, along with 24/7 customer support in case of emergencies.",
      isActive: false,
    },
  ];

  return (
    <section className={styles.container2}>
      <header className={styles.header}>
        <div className={styles.eyebrowAndHeading}>
          <p className={styles.eyebrow}>Why us?</p>
          <h1 className={styles.heading}>Book cars quickly and easily now</h1>
        </div>
        <p className={styles.supportingText}>
          Seeking a vehicle for ongoing transport needs? Got seasonal tasks
          requiring a reliable truck? Looking for an extended rental solution?
          You've found the right place.
        </p>
      </header>
      <div className={styles.featureList}>
        <div className={styles.featureListContainer}>
          {features.map((feature, index) => (
            <article key={index} className={styles.featureItem}>
              <div
                className={`${styles.featureBar} ${
                  feature.isActive ? styles.featureBarActive : ""
                }`}
              />
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>
                  {feature.description}
                </p>
              </div>
            </article>
          ))}
        </div>
        <div className={styles.featureImageContainer}>
          <img
            src="/landing/truckpark.jfif"
            alt="A modern truck representing our wide range of vehicles"
            className={styles.featureImage}
          />
        </div>
      </div>
    </section>
  );
};

export default Features;

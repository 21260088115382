import React, { useState } from "react";
import styles from "./ImageViewer.module.css";

interface ImageViewerProps {
  images: string[];
  setImageViewer: (value: boolean) => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  images,
  setImageViewer,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className={styles.imageViewer}>
        <button
          className={`${styles.arrow} ${styles.leftArrow}`}
          onClick={goToPrevious}
        >
          &#8592;
        </button>
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className={styles.image}
        />
        <button
          className={`${styles.arrow} ${styles.rightArrow}`}
          onClick={goToNext}
        >
          &#8594;
        </button>
      </div>
      <img
        className={styles.iconsolidx}
        alt="Close"
        src="/generics/close.png"
        onClick={(event) => setImageViewer(false)}
      />
    </>
  );
};

export default ImageViewer;
